import { createSlice } from "@reduxjs/toolkit";
import { ShippingCity, ShippingCountry, ShippingState } from "utils/types/shipping";

export type ShippingStateType = {
  readonly countries: ShippingCountry[] | null;
  readonly states: ShippingState[] | null;
  readonly cities: ShippingCity[] | null;
  searchValue: "";
  loading: boolean;
};

const INITIAL_STATE: ShippingStateType = {
  countries: null,
  states: null,
  cities: null,
  searchValue: "",
  loading: false,
};

const shippingSlice = createSlice({
  name: "shipping",
  initialState: INITIAL_STATE,
  reducers: {
    setCountries(state, action) {
      state.countries = action.payload;
    },
    setStates(state, action) {
      state.states = action.payload;
    },
    setCities(state, action) {
      state.cities = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setIsLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const shippingActions = shippingSlice.actions;
export default shippingSlice;

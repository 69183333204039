import {
  Action,
  withMatcher,
  createAction,
  ActionWithPayload,
} from "utils/reducer/reducer.utils";
import { Brands } from "utils/types/brand";
import { shippingActions } from "./shipping.slice";
import { SHIPPING_ACTION_TYPES } from "./shipping.types";

export const setAllCountries = (value: Brands) =>
  shippingActions.setCountries(value);

export const setBrandSearchValue = (value: string) =>
  shippingActions.setSearchValue(value);
type ActivateData = {
  type: "country" | "state" | "city";
  data: number[];
  status: boolean;
};

export type FetchAllData = Action<SHIPPING_ACTION_TYPES.FETCH_ALL_DATA>;
export type ActivateShipping = ActionWithPayload<
  SHIPPING_ACTION_TYPES.ACTIVATE_SHIPPING,
  ActivateData
>;

export const fetchAllCountries = withMatcher(
  (): FetchAllData => createAction(SHIPPING_ACTION_TYPES.FETCH_ALL_DATA)
);

export const activateShipping = withMatcher(
  (data: ActivateData): ActivateShipping =>
    createAction(SHIPPING_ACTION_TYPES.ACTIVATE_SHIPPING, data)
);

function ProductSvg({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width={26}
      height={26}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_241_6643)">
        <path
          d="M21.7751 6.6237H16.5667V4.54036C16.5667 3.39453 15.6292 2.45703 14.4834 2.45703H12.4001C11.2542 2.45703 10.3167 3.39453 10.3167 4.54036V6.6237H5.1084V23.2904H21.7751V6.6237ZM20.7334 22.2487H6.15007V7.66536H20.7334V22.2487ZM11.3584 4.54036C11.3584 3.96589 11.8256 3.4987 12.4001 3.4987H14.4834C15.0579 3.4987 15.5251 3.96589 15.5251 4.54036V6.6237H11.3584V4.54036Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_241_6643">
          <rect
            width={25}
            height={25}
            fill="white"
            transform="translate(0.941406 0.373047)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ProductSvg;

import { Button } from "@nextui-org/react";
import { alertMessage } from "components/toolkit/initial-state.component";
import { apiRequest } from "lib/api/apiClient";
import { useState } from "react";
import { VscSend } from "react-icons/vsc";
import { TicketResponse } from "utils/types/ticket";

function SendMessage({
  ticket,
  setTicket,
}: {
  ticket: TicketResponse;
  setTicket: React.Dispatch<React.SetStateAction<TicketResponse>>;
}) {
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    if (message.length < 0) {
      alertMessage("warn", "Please type in a ");
      return;
    }

    try {
      const res = await apiRequest("/api/v1/ticket/response", {
        method: "POST",
        body: {
          response: message,
          ticket_id: ticket.id,
        },
      });
      if (res) {
        alertMessage("success", "Ticket opened successfully");
        setMessage("");
        setTicket(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  if (ticket.status === "closed") return <></>;
  return (
    <form
      onSubmit={handleSubmit}
      className="w-full">
      <div className="relative w-full flex items-center bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 h-fit p-0.5 dark:bg-primary dark:border-gray-600 dark:placeholder-gray-400 dark:text-white outline-none dark:focus:ring-blue-500 dark:focus:border-blue-500 ">
        <input
          type="text"
          id="news-email"
          name="response"
          className="bg-transparent w-full h-full outline-none mx-2"
          placeholder="Type a message and press enter to send"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button
          type="submit"
          variant="light"
          isIconOnly
          isLoading={loading}
          isDisabled={loading}>
          <VscSend />
        </Button>
      </div>
    </form>
  );
}

export default SendMessage;

import { LoaderFunctionArgs } from "react-router-dom";
import { getHeaders } from "utils/header";
import { getEnvProps } from "utils/helper/server-helper";
import { TicketResponse } from "utils/types/ticket";

const getTicketByCode = async (
  code: string
): Promise<TicketResponse | null> => {
  const envProps = await getEnvProps();
  const headers = await getHeaders();

  let ticket: TicketResponse | null = null;

  try {
    const req = await fetch(
        `${envProps.base_url}/api/v1/ticket/${code}/responses`,
        {
          headers,
        }
      ),
      res = await req.json();

    if (res.success != null && res.success) {
      return res.data;
    } else {
      throw new Error("Error 404, ticket not found.");
    }
  } catch (error) {
    console.error("Error fetching ticket:", error);
    // throw new Error("Error fetching ticket");
  }

  return ticket;
};

export const ticketLoader = async ({ params }: LoaderFunctionArgs) => {
  const { code } = params;
  if (code) {
    const ticket = await getTicketByCode(code);
    if (ticket == null) {
      throw new Response("Error 404, ticket not found.", { status: 404 });
    }

    return ticket;
  }
  return null;
};

import { alertMessage } from "components/toolkit/initial-state.component";
import { getHeaders } from "utils/header";
import { getEnvProps } from "utils/helper/server-helper";
import { Category } from "utils/types/category";

type CategoryFormData = {
  image: FormDataEntryValue | null;
  icon: FormDataEntryValue | null;
  name: FormDataEntryValue | null;
  commision_rate: FormDataEntryValue | null;
  meta_title: FormDataEntryValue | null;
  meta_description: FormDataEntryValue | null;
  parent_id: FormDataEntryValue | null;
  tax: FormDataEntryValue | null;
};

export type CategoryValidationErrors = {
  image?: string;
  icon?: string;
  name?: string;
};

const validateForm = (category: CategoryFormData): CategoryValidationErrors => {
  const errors: CategoryValidationErrors = {};

  if (!category.name) {
    errors.name = "Please name is required";
  }
  if (!category.parent_id) {
    if (!category.icon) {
      errors.icon = "Please icon is required";
    }
    if (!category.image) {
      errors.image = "Please banner is required";
    }
  }
  return errors;
};

const isFormValidated = (category: CategoryFormData) => {
  const errors = {
    name: !category.name,
    icon: !category.parent_id && !category.icon,
    image: !category.parent_id && !category.image,
  };
  return Object.values(errors).every((error) => !error);
};

export const addCategory = async (prevState: any, formData: FormData) => {
  const envProps = await getEnvProps();
  const hostURL = envProps.base_url;

  console.log("submitted");
  const category = {
    image: formData.get("image"),
    icon: formData.get("icon"),
    name: formData.get("name"),
    commision_rate: formData.get("commision_rate"),
    meta_title: formData.get("meta_title"),
    meta_description: formData.get("meta_description"),
    parent_id: formData.get("parent_id"),
    tax: formData.get("tax"),
  };

  console.log(isFormValidated(category), category.parent_id);

  if (!isFormValidated(category)) {
    return {
      message: "Invalid inputs",
      errors: validateForm(category),
      success: false,
    };
  }

  try {
    const uri = `${hostURL}/api/v1/admin/category`;
    const headers = await getHeaders(undefined, true);
    const req = await fetch(uri, {
      method: "POST",
      headers,
      body: formData,
    });

    if (!req.ok) {
      const res = await req.json();
      throw new Error(res.message || "Failed to add category");
    }

    const res = await req.json();

    return {
      message: "Category Uploaded Successfully",
      success: true,
      data: res.data,
    };
  } catch (error) {
    if (error instanceof Error) {
      return {
        message: error.message,
        success: false,
        data: null,
      };
    } else {
      return {
        message: "An unexpected error occurred",
        success: false,
        data: null,
      };
    }
  }
};

export const editCategory = async (prevState: any, formData: FormData) => {
  const category = {
    image: formData.get("image"),
    icon: formData.get("icon"),
    name: formData.get("name"),
    commision_rate: formData.get("commision_rate"),
    meta_title: formData.get("meta_title"),
    meta_description: formData.get("meta_description"),
    parent_id: formData.get("parent_id"),
    tax: formData.get("tax"),
  };
  const uri = String(formData.get("uri"));

  if (!isFormValidated(category)) {
    return {
      message: "Invalid inputs",
      errors: validateForm(category),
      success: false,
    };
  }

  try {
    const headers = await getHeaders(undefined); // delete headers["Content-Type"]; // Let the browser set this automatically// Get the form element

    // Create an empty object to hold the key/value pairs
    const jsonObject: { [key: string]: any } = {};

    // Iterate over each key/value pair in the FormData object
    formData.forEach((value, key) => {
      // Check if the key already exists in the jsonObject
      if (jsonObject.hasOwnProperty(key)) {
        // If it's an array, append the new value
        if (Array.isArray(jsonObject[key])) {
          jsonObject[key].push(value);
        } else {
          // If it's not an array, convert it to an array and append the new value
          jsonObject[key] = [jsonObject[key], value];
        }
      } else {
        // If the key doesn't exist, simply assign the value
        jsonObject[key] = value;
      }
    });

    // Convert the JavaScript object to a JSON string
    const jsonString = JSON.stringify(jsonObject);
    const req = await fetch(uri, {
      method: "PUT",
      headers,
      body: jsonString,
    });

    if (!req.ok) {
      const res = await req.json();
      throw new Error(res.message || "Failed to add category");
    }

    const res = await req.json();

    alertMessage("success", "Category Updated Successfully");
    return {
      message: "Category Updated Successfully",
      success: true,
      data: res.data,
    };
  } catch (error) {
    if (error instanceof Error) {
      return {
        message: error.message,
        success: false,
        data: null,
      };
    } else {
      return {
        message: "An unexpected error occurred",
        success: false,
        data: null,
      };
    }
  }
};

export const getCategoryBySlug = async (
  slug: string
): Promise<Category | null> => {
  const envProps = await getEnvProps();
  const headers = await getHeaders(undefined, true);

  let category: Category | null = null;

  try {
    const req = await fetch(
        `${envProps.base_url}/api/v1/admin/get-category/${slug}`,
        {
          headers,
        }
      ),
      res = await req.json();

    if (res.success != null && res.success) {
      return res.data;
    } else {
      throw new Error("Error 404, Category not found.");
    }
  } catch (error) {
    console.error("Error fetching category:", error);
    // throw new Error("Error fetching category");
  }

  return category;
};

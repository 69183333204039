import { takeLatest, all, call, put } from "typed-redux-saga";

import { apiRequest } from "lib/api/apiClient";
import { MARKETING_ACTION_TYPES } from "./marketing.types";
import { marketingActions } from "./marketing.slice";
import { logger } from "lib/logger";

function* fetchPositions() {
  yield* put(marketingActions.setIsLoading(true));
  try {
    const link = "/api/v1/admin/bannerPosition";
    const data = yield* call(apiRequest, link);
    if (!data) return;
    yield* put(marketingActions.setBannerPositions(data));
  } catch (error) {
    logger.error(error as Error);
  } finally {
    yield* put(marketingActions.setIsLoading(false));
  }
}
function* fetchBanners() {
  yield* put(marketingActions.setIsLoading(true));
  try {
    const link = "/api/v1/admin/banner";
    const data = yield* call(apiRequest, link);
    if (!data) return;
    yield* put(marketingActions.setBanners(data));
  } catch (error) {
    logger.error(error as Error);
  } finally {
    yield* put(marketingActions.setIsLoading(false));
  }
}
function* onFetchData() {
  yield* takeLatest(MARKETING_ACTION_TYPES.FETCH_ALL_DATA, fetchPositions);
  yield* takeLatest(MARKETING_ACTION_TYPES.FETCH_ALL_DATA, fetchBanners);
}

export function* marketingSagas() {
  yield* all([call(onFetchData)]);
}

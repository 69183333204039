import { takeLatest, all, call, put } from "typed-redux-saga";

import { COUPON_ACTION_TYPES } from "./coupon.types";
import { AddCoupon, DeleteCoupon, UpdateCoupon } from "./coupon.actions";
import { couponActions } from "./coupon.slice";
import { apiRequest } from "lib/api/apiClient";
import { alertMessage } from "components/toolkit/initial-state.component";
import { logger } from "lib/logger";

export function* addCouponFunc({ payload: formValues }: AddCoupon) {
  const { callback, ...form_data } = formValues;
  yield* put(couponActions.setLoading(true));
  try {
    const link = "/api/v1/admin/coupon";
    const data = yield* call(apiRequest, link, {
      method: "post",
      body: form_data,
    });
    if (!data) return;
    yield* put(couponActions.setAllCoupon(data));
    alertMessage("success", "coupon added successfully");
    callback();
  } catch (error) {
    logger.error(error as Error);
    alertMessage("error", "Failed to add coupon");
  } finally {
    yield* put(couponActions.setLoading(false));
  }
}

export function* updateCouponFunc({ payload: formValues }: UpdateCoupon) {
  const { uri, ...form_data } = formValues;
  yield* put(couponActions.setLoading(true));
  try {
    const data = yield* call(
      apiRequest,
      uri,
      {
        method: "put",
        body: form_data,
      },
      true
    );
    if (!data) return;
    yield* put(couponActions.setAllCoupon(data));
    alertMessage("success", "Coupon updated successfully");
  } catch (error) {
    logger.error(error as Error);
    alertMessage("error", "Failed to updated coupon");
  } finally {
    yield* put(couponActions.setLoading(false));
  }
}
export function* deleteCouponFunc({ payload: formValues }: DeleteCoupon) {
  yield* put(couponActions.setLoading(true));
  try {
    const link = formValues.uri;
    const data = yield* call(
      apiRequest,
      link,
      {
        method: "delete",
      },
      true
    );
    if (!data) return;
    yield* put(couponActions.setAllCoupon(data));
    alertMessage("success", "Coupon added successfully");
  } catch (error) {
    logger.error(error as Error);
    alertMessage("error", "Failed to add coupon");
  } finally {
    yield* put(couponActions.setLoading(false));
  }
}

export function* fetchCouponData() {
  try {
    const data = yield* call(apiRequest, "/api/v1/admin/coupon");
    if (!data) return;
    yield* put(couponActions.setAllCoupon(data));
  } catch (error) {
    console.log(error as Error);
  }
}

export function* onAddCoupon() {
  yield* takeLatest(COUPON_ACTION_TYPES.ADD_COUPON, addCouponFunc);
}

export function* onFetchCoupons() {
  yield* takeLatest(COUPON_ACTION_TYPES.FETCH_ALL_DATA, fetchCouponData);
}
export function* onDeleteCoupon() {
  yield* takeLatest(COUPON_ACTION_TYPES.DELETE_COUPON, deleteCouponFunc);
}
export function* onUpdateCoupon() {
  yield* takeLatest(COUPON_ACTION_TYPES.UPDATE_COUPON, updateCouponFunc);
}
export function* couponSagas() {
  yield* all([
    call(onAddCoupon),
    call(onFetchCoupons),
    call(onDeleteCoupon),
    call(onUpdateCoupon),
  ]);
}

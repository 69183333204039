import { all, call } from "typed-redux-saga";
import { dataSagas } from "./data/data.saga";
import { userSagas } from "./user/user.saga";
import { categorySagas } from "./category/category.saga";
import { brandSagas } from "./brand/brand.saga";
import { staffSagas } from "./staff/staff.saga";
import { attributeSagas } from "./attribute/attribute.saga";
import { colorSagas } from "./color/color.saga";
import { productSagas } from "./product/product.saga";
import { usersSagas } from "./users/users.saga";
import { blogSagas } from "./blog/blog.saga";
import { couponSagas } from "./coupon/coupon.saga";
import { policySagas } from "./policy/policy.saga";
import { supportSagas } from "./tickets/ticket.saga";
import { activitySagas } from "./activity/activity.saga";
import { shippingSagas } from "./shipping/shipping.saga";
import { marketingSagas } from "./marketing/marketing.saga";

export function* rootSaga() {
  yield* all([
    call(dataSagas),
    call(userSagas),
    call(categorySagas),
    call(brandSagas),
    call(staffSagas),
    call(attributeSagas),
    call(colorSagas),
    call(productSagas),
    call(usersSagas),
    call(blogSagas),
    call(couponSagas),
    call(policySagas),
    call(supportSagas),
    call(activitySagas),
    call(shippingSagas),
    call(marketingSagas),
  ]);
}

function BlogSvg({ className }: { className?: string }) {
  return (
    <svg
      width={25}
      height={25}
      className={className}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.636 12C7.867 12 4 16.477 4 22C4 26.855 6.989 30.899 10.952 31.807L14 44H26L23.181 32.725L44 39.647V4L21 12H12.636ZM15.562 42L13.062 32H20.939L23.439 42H15.562ZM12.636 14H21V30H12.636C8.977 30 6 26.411 6 22C6 17.589 8.977 14 12.636 14ZM42 36.874L23 30.557V13.422L42 6.813V36.874Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default BlogSvg;

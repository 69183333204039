import { CompanyEmail, Permissions, Roles, Staff } from "utils/types/staff";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type StaffState = {
  readonly users: Staff[] | null;
  readonly emails: CompanyEmail[] | null;
  readonly roles: Roles | null;
  readonly permissions: Permissions | null;
  loading: boolean;
};

const INITIAL_STATE: StaffState = {
  users: null,
  emails: null,
  permissions: null,
  roles: null,
  loading: false,
};
const staffSlice = createSlice({
  name: "staff",
  initialState: INITIAL_STATE,
  reducers: {
    setPermissions(state, action: PayloadAction<Permissions>) {
      state.permissions = action.payload;
    },
    setRoles(state, action: PayloadAction<Roles>) {
      state.roles = action.payload;
    },
    setStaff(state, action: PayloadAction<Staff[]>) {
      state.users = action.payload;
    },
    setEmails(state, action: PayloadAction<CompanyEmail[]>) {
      state.emails = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const staffActions = staffSlice.actions;
export default staffSlice;

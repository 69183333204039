import CheronRight from "assets/svgs/cheronright.svg";
import { links } from "utils/helper/links";
import { Link } from "react-router-dom";
import { TicketResponse } from "utils/types/ticket";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "store/user/user.selector";
import PassIssue from "./pass-issue";
import MarkasClosed from "./mark-closed";

function TicketResponsePageHeader(props: {
  ticket: TicketResponse;
  setTicket: React.Dispatch<React.SetStateAction<TicketResponse>>;
}) {
  const user = useSelector(selectCurrentUser);
  const hasPermission = [25].some((permission) =>
    user?.role?.permissions.includes(permission)
  );

  return (
    <div className="block justify-between page-header sm:flex">
      <div>
        <h3 className="text-dark-base hover:text-gray-900 dark:text-white dark:hover:text-white text-2xl font-medium">
          Tickets
        </h3>
        <ol className="flex items-center whitespace-nowrap min-w-0 mt-2">
          <li className="text-sm">
            <Link
              className="flex items-center text-gray-500 font-semibold hover:text-primary dark:text-gray-200 truncate"
              to={links.dashboard}>
              Dashboard
              <CheronRight className="flex-shrink-0 mx-3 overflow-visible text-gray-300 dark:text-gray-300 rtl:rotate-180" />
            </Link>
          </li>
          <li className="text-sm">
            <Link
              className="flex items-center text-gray-500 font-semibold hover:text-primary dark:text-gray-200 truncate"
              to={"/support/tickets"}>
              Tickets
              <CheronRight className="flex-shrink-0 mx-3 overflow-visible text-gray-300 dark:text-gray-300 rtl:rotate-180" />
            </Link>
          </li>
          <li
            className="text-sm hover:text-primary dark:text-white/70 "
            aria-current="page">
            Resolve Ticket
          </li>
        </ol>
      </div>
      <div className="flex items-center whitespace-nowrap min-w-0 gap-2">
        {hasPermission && props.ticket.status !== "closed" && (
          <PassIssue {...props} />
        )}

        <MarkasClosed {...props} />
      </div>
    </div>
  );
}

export default TicketResponsePageHeader;

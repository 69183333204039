import { ChangeEvent, useEffect, useState } from "react";
import PageSearch from "components/main/page-search.component";
import useDebounce from "utils/hooks/useDebounce";
import { useDispatch } from "react-redux";
import DateRangeSelector from "./filters/duration.component";
import ProductSortBy from "./filters/sort.component";
import ProductRows from "./filters/rows.component";
import { supportActions } from "store/tickets/ticket.slice";
import RightDrawer from "components/main/right-drawer.component";

function TicketsFilter() {
  const [searchValue, setSearchValue] = useState(""),
    dispatch = useDispatch(),
    deBounceValue = useDebounce(searchValue, 1000);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  useEffect(() => {
    dispatch(supportActions.setSearchValue(deBounceValue));
  }, [deBounceValue, dispatch]);
  return (
    <div className="flex items-center justify-between">
      <PageSearch {...{ searchValue, handleChange }} />

      <RightDrawer>
        <div className="space-y-4">
          <DateRangeSelector />
          <ProductSortBy />
          <ProductRows />
        </div>
      </RightDrawer>
    </div>
  );
}

export default TicketsFilter;

import { getHeaders } from "utils/header";
import { getEnvProps } from "utils/helper/server-helper";
import { Attribute } from "utils/types/attribute";

export const getAttributeByID = async (
  id: string
): Promise<Attribute | null> => {
  const envProps = await getEnvProps();
  const headers = await getHeaders(undefined, true);

  let attribute: Attribute | null = null;

  try {
    const req = await fetch(
        `${envProps.base_url}/api/v1/admin/attribute/${id}`,
        {
          headers,
        }
      ),
      res = await req.json();

    if (res.success != null && res.success) {
      return res.data;
    } else {
      throw new Error("Error 404, attribute not found.");
    }
  } catch (error) {
    console.error("Error fetching attribute:", error);
    // throw new Error("Error fetching attribute");
  }

  return attribute;
};

import { getFromCookieProtected } from "./helper/helper";
import { AUTH_TOKEN } from "./helper/states";
import { AccessToken } from "./types/user";

export const getHeaders = async (
  authToken?: AccessToken,
  isFormData: boolean = false
): Promise<Record<string, string>> => {
  const apiKey = process.env.REACT_APP_API_KEY;

  // Handle the case where API Key is undefined
  if (!apiKey) {
    throw new Error("API Key is not defined in the environment variables.");
  }

  // Retrieve token from cookies or fallback to provided authToken
  const _token = getFromCookieProtected<AccessToken>(AUTH_TOKEN);
  const token = authToken ?? _token;

  const headers: Record<string, string> = {
    Accept: "application/json",
    ...(token && {
      Authorization: `${token.token_type} ${token.access_token}`,
    }),
    "X-Api-Key": apiKey,
    ...(!isFormData && {
      "Content-Type": "application/json",
    }),
  };

  return headers;
};

import { Action, withMatcher, createAction } from "utils/reducer/reducer.utils";

import { policyActions } from "./policy.slice";
import { POLICY_ACTION_TYPES } from "./policy.types";
import { Policy } from "utils/types/policies";

export const setAllPolicy = (value: Policy[]) =>
  policyActions.setAllPolicy(value);

export const setPolicySearchValue = (value: string) =>
  policyActions.setSearchValue(value);

export type FetchAllData = Action<POLICY_ACTION_TYPES.FETCH_ALL_DATA>;

export const fetchAllPolicy = withMatcher(
  (): FetchAllData => createAction(POLICY_ACTION_TYPES.FETCH_ALL_DATA)
);

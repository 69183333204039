import { createSlice } from "@reduxjs/toolkit";
import { Activity } from "utils/types/activity";
import sound from "assets/sounds/sound1.ogg";

// Define a function to play the notification sound
export const playNotificationSound = () => {
  const audio = new Audio(sound);
  audio.play().catch((error) => {
    console.log("Error playing sound:", error);
  });
};

export type ActivityState = {
  readonly all: Activity[] | null;
  searchValue: "";
  loading: boolean;
};

const INITIAL_STATE: ActivityState = {
  all: null,
  searchValue: "",
  loading: false,
};

const activitySlice = createSlice({
  name: "activity",
  initialState: INITIAL_STATE,
  reducers: {
    setAllActivity(state, action) {
      if (state.all != null) {
        if (state.all.length !== action.payload.length) {
          // Play the notification sound if the length has changed
          playNotificationSound();
        }
      }
      state.all = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setIsLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const activityActions = activitySlice.actions;
export default activitySlice;

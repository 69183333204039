import DashboardLayout from "components/layouts/main-layout";
import TicketsFilter from "components/support/filters.component";
import TicketPageHeader from "components/support/page-header.component";
import TicketList from "components/support/table.component";

function SupportPage() {
  return (
    <DashboardLayout name="support">
      <main className="main-content relative">
        <TicketPageHeader />
        <TicketsFilter />
        <TicketList />
      </main>
    </DashboardLayout>
  );
}

export default SupportPage;

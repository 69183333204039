import { createSelector } from "reselect";
import { RootState } from "../store";

const selectColorReducer = (state: RootState) => state.color;

export const selectColors = createSelector(
  [selectColorReducer],
  (data) => data.all
);

export const selectColorByID = (id: number) =>
  createSelector([selectColorReducer], (data) =>
    data.all?.find((i) => i.id === id)
  );

export const selectColorLoading = createSelector(
  [selectColorReducer],
  (data) => data.loading
);

export const selectFilteredColors = createSelector(
  [selectColorReducer],
  (data) => {
    const colors = data.all,
      searchValue = data.searchValue;
    const filtered = colors?.filter((color) => {
      const colorNameMatches = color.name
        .toLowerCase()
        .includes(searchValue.toLowerCase());
      return colorNameMatches;
    });
    return filtered == null ? null : filtered;
  }
);

import { useRouteError, isRouteErrorResponse, Link } from "react-router-dom";
import NotFoundSvg from "./404.svg";
import { RiArrowLeftLine } from "react-icons/ri";

function ErrorPage() {
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    return (
      <div className="w-full h-screen flex flex-col items-center justify-center px-4">
        {error.status === 404 && <NotFoundSvg />}
        <div className="flex flex-col items-center justify-center">
          <p className="text-3xl md:text-4xl lg:text-5xl text-gray-800 dark:text-casagray-100 mt-12">
            {error.status === 404 ? "Page Not Found" : error.data}
          </p>
          <p className="md:text-lg lg:text-xl text-casagray-600 dark:text-casagray-200 mt-8">
            {error.status === 404
              ? "Sorry, the page you are looking for could not be found."
              : "Sorry, An error Occured. You can not proceed."}
          </p>
          <Link
            to="/"
            className="flex items-center space-x-2 bg-orange-600 hover:bg-orange-700 text-gray-100 px-4 py-2 mt-12 rounded transition duration-150"
            title="Return Home">
            <RiArrowLeftLine className="h-5 w-5" />
            <span>Return Home</span>
          </Link>
        </div>
      </div>
    );
  } else if (error instanceof Error) {
    return (
      <div className="w-full h-screen flex flex-col items-center justify-center px-4">
        <div className="flex flex-col items-center justify-center">
          <p className="text-3xl md:text-4xl lg:text-5xl text-gray-800 dark:text-casagray-100 mt-12">
            Oops! Unexpected Error
          </p>
          <p className="md:text-lg lg:text-xl text-casagray-600 dark:text-casagray-200 mt-8">
            {error.message}
          </p>
          <Link
            to="/"
            className="flex items-center space-x-2 bg-orange-600 hover:bg-orange-700 text-gray-100 px-4 py-2 mt-12 rounded transition duration-150"
            title="Return Home">
            <RiArrowLeftLine className="h-5 w-5" />
            <span>Return Home</span>
          </Link>
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-full h-screen flex flex-col items-center justify-center px-4">
        <div className="flex flex-col items-center justify-center">
          <p className="text-3xl md:text-4xl lg:text-5xl text-gray-800 dark:text-casagray-100 mt-12">
            Oops! Unexpected Error
          </p>
          <p className="md:text-lg lg:text-xl text-casagray-600 dark:text-casagray-200 mt-8">
            Sorry, An error Occured please try again later.
          </p>
          <Link
            to="/"
            className="flex items-center space-x-2 bg-orange-600 hover:bg-orange-700 text-gray-100 px-4 py-2 mt-12 rounded transition duration-150"
            title="Return Home">
            <RiArrowLeftLine className="h-5 w-5" />
            <span>Return Home</span>
          </Link>
        </div>
      </div>
    );
  }
}

export default ErrorPage;

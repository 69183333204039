export enum BadgeType {
  success = "badge/SUCCESS",
  pending = "badge/PENDING",
  danger = "badge/DANGER",
  default = "badge/DEFAULT",
}

type Props = {
  type?: BadgeType;
  children?: React.ReactNode | string;
};

function Badge({ type, children }: Props) {
  switch (type) {
    case BadgeType.success:
      return (
        <div className="badge bg-success/20 text-[#0D894F] dark:text-white dark:bg-[#0D894F]">
          {children}
        </div>
      );
    case BadgeType.pending:
      return (
        <div className="badge bg-gray-500/20 text-gray-500 dark:text-white dark:bg-gray-500">
          {children}
        </div>
      );
    case BadgeType.danger:
      return (
        <div className="badge bg-red-500/40 text-red-500 dark:text-white dark:bg-red-500">
          {children}
        </div>
      );

    default:
      return (
        <div className="badge bg-secondary-400/20 text-secondary-400 dark:text-white dark:bg-secondary-400">
          {children}
        </div>
      );
  }
}

export default Badge;

import {
  ActionWithPayload,
  Action,
  withMatcher,
  createAction,
} from "utils/reducer/reducer.utils";
import { AccessToken } from "utils/types/user";
import { CATEGORY_ACTION_TYPES } from "./category.types";
import { Categories } from "utils/types/category";
import { categoryActions } from "./category.slice";

export type FetchAllData = Action<CATEGORY_ACTION_TYPES.FETCH_ALL_DATA>;

export const fetchAllCategory = withMatcher(
  (): FetchAllData => createAction(CATEGORY_ACTION_TYPES.FETCH_ALL_DATA)
);

export type SetData = ActionWithPayload<
  CATEGORY_ACTION_TYPES.SET_DATA,
  Categories
>;

type UpdateCategoryProp = {
  uri: string;
  formData: FormData;
};
export type UpdateCategory = ActionWithPayload<
  CATEGORY_ACTION_TYPES.UPDATE_CATEGORY,
  UpdateCategoryProp
>;
export const updateCategory = withMatcher(
  (data: UpdateCategoryProp): UpdateCategory =>
    createAction(CATEGORY_ACTION_TYPES.UPDATE_CATEGORY, data)
);
export const setAllCategory = (value: Categories) =>
  categoryActions.setAllCategory(value);

export const setCategorySearchValue = (value: string) =>
  categoryActions.setSearchValue(value);

export const setCategoryLoading = (value: boolean) =>
  categoryActions.setIsLoading(value);

import { SvgProps } from "utils/types/app";

function CheckboxSvg(props: SvgProps) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M4.30078 3.33398V15.834H16.8008V9.02357H16.3841H15.9674V15.0007H5.13411V4.16732H12.6541V3.75107V3.33398H4.30078Z"
        fill="currentColor"
      />
      <path
        d="M7.92901 7.62174L7.63443 7.91633L10.2565 10.5384L7.63443 7.91633L7.33984 8.21091L10.2565 11.1276L10.5511 10.833L10.8457 11.1276L14.9619 7.01133L17.929 4.04424L17.3398 3.45508L14.4736 6.32133L10.5511 10.2438L7.92901 7.62174Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default CheckboxSvg;

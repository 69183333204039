import { Button } from "@nextui-org/react";
import CheckboxSvg from "assets/svgs/checkbox.svg";
import { alertMessage } from "components/toolkit/initial-state.component";
import { apiRequest } from "lib/api/apiClient";
import { logger } from "lib/logger";
import { useState } from "react";
import { TicketResponse } from "utils/types/ticket";

function MarkasClosed({
  ticket,
  setTicket,
}: {
  ticket: TicketResponse;
  setTicket: React.Dispatch<React.SetStateAction<TicketResponse>>;
}) {
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    setLoading(true);
    try {
      const res = await apiRequest(`/api/v1/admin/ticket/${ticket.id}`, {
        method: "put",
        body: {
          status: "closed",
        },
      });
      if (res) {
        alertMessage("success", "Ticket marked successfully");
        setTicket(res);
      }
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Button
      color="secondary"
      isLoading={loading}
      onPress={handleClick}>
      {!loading && <CheckboxSvg />} Mark as Closed{" "}
    </Button>
  );
}
export default MarkasClosed;

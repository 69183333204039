import { SideBarLink } from "utils/types/links";
import SvgData from "assets/svgs";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
} from "@nextui-org/react";
import { classNames } from "utils/helper/helper";
import { Link, useLocation } from "react-router-dom";

function SidebarItem({ item }: { item: SideBarLink }) {
  const location = useLocation();
  const path = location.pathname;
  const _isActive =
      item.uri != null
        ? path.startsWith(item.uri)
        : item.for != null && path.startsWith(item.for),
    isActive =
      item.uri != null && item.uri === "/" ? item.uri === path : _isActive,
    childItems = item.children?.map((link, i) => ({
      ...link,
      key: i,
      label: link.title,
    }));

  return (
    <li
      className={classNames(
        isActive
          ? "text-secondary-400 dark:text-dark-border"
          : "text-base-color dark:text-white",
        "group-[.sidebar-size-sm]:flex group-[.sidebar-size-sm]:justify-center group-[.sidebar-size-sm]:items-center"
      )}>
      {item.children == null && (
        <Link
          to={item.uri!}
          className={classNames(
            isActive ? "bg-secondary-400 text-dark-base" : "",
            "group-[.sidebar-size-sm]:px-2 group-[.sidebar-size-sm]:flex group-[.sidebar-size-sm]:justify-center"
          )}>
          <SvgData
            name={item.icon}
            className="w-6 h-6"
          />
          <span className="group-[.sidebar-size-sm]:hidden">{item.title}</span>
        </Link>
      )}
      {item.children != null && (
        <>
          <details
            open={isActive}
            className="group-[.sidebar-size-sm]:hidden">
            <summary
              className={
                isActive
                  ? "bg-light text-secondary-400 dark:text-dark-base"
                  : ""
              }>
              <SvgData
                name={item.icon}
                className="w-6 h-6"
              />
              <span>{item.title}</span>
            </summary>
            <ul className="space-y-1.5 mt-2">
              {item.children.map((child, i) => (
                <li key={i}>
                  <Link
                    to={child.uri}
                    className={
                      path.startsWith(child.uri)
                        ? "bg-secondary-400 hover:bg-secondary-500 text-dark-base"
                        : "text-base-color dark:text-white"
                    }>
                    {child.title}
                  </Link>
                </li>
              ))}
            </ul>
          </details>
          <Dropdown>
            <DropdownTrigger>
              <Button
                isIconOnly
                variant="light"
                className={classNames(
                  isActive
                    ? "bg-light text-secondary-400 dark:text-dark-base"
                    : "text-base-color dark:text-white",
                  "hidden group-[.sidebar-size-sm]:flex"
                )}>
                <SvgData
                  name={item.icon}
                  className="w-6 h-6"
                />
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Dynamic Actions"
              items={childItems}>
              {(item) => (
                <DropdownItem
                  key={item.key}
                  color={path.startsWith(item.uri) ? "secondary" : "default"}
                  as={Link}
                  href={item.uri}
                  className={
                    path.startsWith(item.uri)
                      ? "bg-secondary-400 hover:bg-secondary-500 text-dark-base"
                      : "text-base-color dark:text-white"
                  }>
                  {item.label}
                </DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        </>
      )}
    </li>
  );
}

export default SidebarItem;

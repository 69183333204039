import { Attribute } from "../types/attribute";

export type AttributeCombination = {
  combination_name: string;
  attributes: { attribute_id: number; attribute_value_id: number }[];
};
export function combineAttributes(
  attributes: Attribute[]
): AttributeCombination[] {
  if (attributes.length === 0) return [];

  const [first, ...rest] = attributes;

  if (first.values.length === 0) return combineAttributes(rest);

  const combinations: AttributeCombination[] = [];

  first.values.forEach((value) => {
    const prefix = `${value.value} (${first.name})`;
    const attrComb = {
      attribute_id: first.id,
      attribute_value_id: value.id,
    };
    if (rest.length > 0) {
      combineAttributes(rest).forEach((restComb) => {
        combinations.push({
          combination_name: `${prefix} - ${restComb.combination_name}`,
          attributes: [attrComb, ...restComb.attributes],
        });
      });
    } else {
      combinations.push({
        combination_name: prefix,
        attributes: [attrComb],
      });
    }
  });

  return combinations;
}

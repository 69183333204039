import { Colors } from "utils/types/color";
import { createSlice } from "@reduxjs/toolkit";

export type ColorState = {
  readonly all: Colors | null;
  searchValue: "";
  loading: boolean;
};

const INITIAL_STATE: ColorState = {
  all: null,
  searchValue: "",
  loading: false,
};
const colorSlice = createSlice({
  name: "color",
  initialState: INITIAL_STATE,
  reducers: {
    setAllColor(state, action) {
      state.all = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const colorActions = colorSlice.actions;
export default colorSlice;

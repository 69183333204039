import { Seller, User } from "utils/types/user";
import { createSlice } from "@reduxjs/toolkit";

export type usersState = {
  readonly customers: User[] | null;
  readonly vendors: Seller[] | null;
};

const INITIAL_STATE: usersState = {
  customers: null,
  vendors: null,
};
const usersSlice = createSlice({
  name: "users",
  initialState: INITIAL_STATE,
  reducers: {
    setCustomers(state, action) {
      state.customers = action.payload;
    },
    setVendors(state, action) {
      state.vendors = action.payload;
    },
  },
});

export const usersActions = usersSlice.actions;
export default usersSlice;

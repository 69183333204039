import { takeLatest, all, call, put } from "typed-redux-saga";

import { apiRequest } from "lib/api/apiClient";
import { STAFF_ACTION_TYPES } from "./staff.types";
import { staffActions } from "./staff.slice";
import {
  AddPermission,
  AddRole,
  AddStaff,
  UpdatePermission,
  UpdateRole,
  UpdateStaff,
} from "./staff.actions";
import { alertMessage } from "components/toolkit/initial-state.component";
import { logger } from "lib/logger";

export function* addPermissionFunc({ payload: formValues }: AddPermission) {
  yield* put(staffActions.setLoading(true));
  try {
    const link = "/api/v1/admin/permission";
    const data = yield* call(apiRequest, link, {
      method: "post",
      body: formValues,
    });
    if (!data) return;
    yield* put(staffActions.setPermissions(data));
    alertMessage("success", "Permission added successfully");
  } catch (error) {
    logger.error(error as Error);
    alertMessage("error", "Failed to add permission");
  } finally {
    yield* put(staffActions.setLoading(false));
  }
}
export function* updatePermissionFunc({
  payload: formValues,
}: UpdatePermission) {
  yield* put(staffActions.setLoading(true));
  try {
    const link = formValues.uri;
    const data = yield* call(
      apiRequest,
      link,
      {
        method: "PUT",
        body: { name: formValues.name },
      },
      true
    );
    if (!data) return;
    yield* put(staffActions.setPermissions(data));
    alertMessage("success", "Permission Update successfully");
  } catch (error) {
    logger.error(error as Error);
    alertMessage("error", "Failed to add permission");
  } finally {
    yield* put(staffActions.setLoading(false));
  }
}
export function* fetchPermissionData() {
  try {
    const data = yield* call(apiRequest, "/api/v1/admin/permission");
    if (!data) return;
    yield* put(staffActions.setPermissions(data));
  } catch (error) {
    console.log(error as Error);
  }
}

// ------------------------------------- Roles -------------------------------------

export function* addRoleFunc({ payload: formValues }: AddRole) {
  const { callback, ...form_data } = formValues;
  yield* put(staffActions.setLoading(true));
  try {
    const link = "/api/v1/admin/role";
    const data = yield* call(apiRequest, link, {
      method: "post",
      body: form_data,
    });
    if (!data) return;
    yield* put(staffActions.setRoles(data));
    alertMessage("success", "Role added successfully");
    callback();
  } catch (error) {
    logger.error(error as Error);
    alertMessage("error", "Failed to add Roles");
  } finally {
    yield* put(staffActions.setLoading(false));
  }
}

export function* updateRoleFunc({ payload: formValues }: UpdateRole) {
  yield* put(staffActions.setLoading(true));
  try {
    const { uri, ...form_data } = formValues;
    const data = yield* call(
      apiRequest,
      uri,
      {
        method: "PUT",
        body: form_data,
      },
      true
    );
    if (!data) return;
    yield* put(staffActions.setRoles(data));
    alertMessage("success", "Permission Update successfully");
  } catch (error) {
    logger.error(error as Error);
    alertMessage("error", "Failed to update Roles");
  } finally {
    yield* put(staffActions.setLoading(false));
  }
}
export function* fetchRoleData() {
  try {
    const data = yield* call(apiRequest, "/api/v1/admin/role");
    if (!data) return;
    yield* put(staffActions.setRoles(data));
  } catch (error) {
    console.log(error as Error);
  }
}

// ------------------------------------- Staff -------------------------------------

export function* addStaffFunc({ payload: formValues }: AddStaff) {
  const { callback, ...form_data } = formValues;
  yield* put(staffActions.setLoading(true));
  try {
    const link = "/api/v1/admin/staff/create";
    const data = yield* call(apiRequest, link, {
      method: "post",
      body: form_data,
    });
    if (!data) return;
    yield* put(staffActions.setStaff(data));
    alertMessage("success", "User added successfully");
    callback();
  } catch (error) {
    logger.error(error as Error);
    alertMessage("error", "Failed to add User");
  } finally {
    yield* put(staffActions.setLoading(false));
  }
}

export function* fetchStaffData() {
  try {
    const data = yield* call(apiRequest, "/api/v1/admin/staff");
    if (!data) return;
    yield* put(staffActions.setStaff(data));
  } catch (error) {
    console.log(error as Error);
  } finally {
    yield* put(staffActions.setLoading(false));
  }
}
function* fetchEmails() {
  try {
    const data = yield* call(apiRequest, "/api/v1/admin/email");
    if (!data) return;
    yield* put(staffActions.setEmails(data));
  } catch (error) {
    console.log(error as Error);
  } finally {
    yield* put(staffActions.setLoading(false));
  }
}

export function* updateStaffFunc({ payload: formValues }: UpdateStaff) {
  yield* put(staffActions.setLoading(true));
  try {
    const { uri, ...form_data } = formValues;
    const data = yield* call(apiRequest, uri, {
      method: "PUT",
      body: form_data,
    });
    if (!data) return;
    yield* put(staffActions.setStaff(data));
    alertMessage("success", "Staff Update successfully");
  } catch (error) {
    logger.error(error as Error);
    alertMessage("error", "Failed to update Staff");
  } finally {
    yield* put(staffActions.setLoading(false));
  }
}
//
// ------------------------------------- Permission Event Listeners -------------------------------------

export function* onAddPermission() {
  yield* takeLatest(STAFF_ACTION_TYPES.ADD_PERMISSION, addPermissionFunc);
}
export function* onUpdatePermission() {
  yield* takeLatest(STAFF_ACTION_TYPES.UPDATE_PERMISSION, updatePermissionFunc);
}
export function* onFetchPermissions() {
  yield* takeLatest(STAFF_ACTION_TYPES.FETCH_PERMISSION, fetchPermissionData);
}

// ------------------------------------- Role Event Listeners -------------------------------------

export function* onAddRole() {
  yield* takeLatest(STAFF_ACTION_TYPES.ADD_ROLE, addRoleFunc);
}
export function* onFetchRole() {
  yield* takeLatest(STAFF_ACTION_TYPES.FETCH_ROLE, fetchRoleData);
}
export function* onUpdateRole() {
  yield* takeLatest(STAFF_ACTION_TYPES.UPDATE_ROLE, updateRoleFunc);
}
// ------------------------------------- Staff Event Listeners -------------------------------------

export function* onAddStaff() {
  yield* takeLatest(STAFF_ACTION_TYPES.ADD_STAFF, addStaffFunc);
}
export function* onFetchStaff() {
  yield* takeLatest(STAFF_ACTION_TYPES.FETCH_STAFF, fetchStaffData);
}
export function* onUpdateStaff() {
  yield* takeLatest(STAFF_ACTION_TYPES.UPDATE_STAFF, updateStaffFunc);
}
// ------------------------------------- Emails Listeners -------------------------------------

export function* onFetchEmails() {
  yield* takeLatest(STAFF_ACTION_TYPES.FETCH_EMAILS, fetchEmails);
}

export function* staffSagas() {
  yield* all([
    call(onAddPermission),
    call(onFetchPermissions),
    call(onUpdatePermission),

    call(onAddRole),
    call(onFetchRole),
    call(onUpdateRole),

    call(onAddStaff),
    call(onFetchStaff),
    call(onUpdateStaff),

    call(onFetchEmails),
  ]);
}

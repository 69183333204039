import { getEnvProps } from "utils/helper/server-helper";
import { getHeaders } from "utils/header";
import validator from "validator";

export async function getUserByID<T>(id: string): Promise<T | null> {
  const envProps = await getEnvProps();
  const headers = await getHeaders(undefined, true);

  let user: T | null = null;

  try {
    const req = await fetch(`${envProps.base_url}/api/v1/admin/users/${id}`, {
        headers,
      }),
      res = await req.json();

    if (res.success != null && res.success) {
      return res.data;
    } else {
      throw new Error("Error 404, User not found.");
    }
  } catch (error) {
    console.error("Error fetching User:", error);
    // throw new Error("Error fetching User");
  }

  return user;
}
type User = {
  email: string;
  password: string;
  name: string;
  password_confirmation: string;
};

type ValidationErrors = {
  name?: string;
  email?: string;
  password?: string;
  password_confirmation?: string;
};

export const isValidateRegForm = (user: User) => {
  const passwordValid = user.password.length >= 8 && user.password.length <= 12;
  const passwordMatch = user.password === user.password_confirmation;

  const errors = {
    name: user.name === "",
    email: user.email === "",
    password: user.password === "" || !passwordValid,
    password_confirmation: user.password_confirmation === "" || !passwordMatch,
  };
  return Object.values(errors).every((error) => !error);
};
export const validateRegForm = (user: User): ValidationErrors => {
  const passwordValid = user.password.length >= 8 && user.password.length <= 12;
  const passwordMatch = user.password === user.password_confirmation;

  const errors: ValidationErrors = {};

  if (user.name === "") {
    errors.name = "Please name is required";
  }
  if (user.email === "") {
    errors.email = "Please email is required";
  }
  if (user.password === "") {
    errors.password = "Password is required";
  } else if (!passwordValid) {
    errors.password = "Password must be between 8 and 12 characters";
  }
  if (user.password_confirmation === "") {
    errors.password_confirmation = "Password confirmation is required";
  } else if (!passwordMatch) {
    errors.password_confirmation = "Passwords do not match";
  }

  return errors;
};

export const validateUserLogin = (data: { [key: string]: string }) => {
  const validationErrors: { [key: string]: string } = {};

  if (data["email"] === "") {
    validationErrors.email = "Please email is required";
  }
  if (data["email"] && !validator.isEmail(data["email"].toString())) {
    validationErrors.email = "This must be of type 'email'";
  }
  if (!data["password"] || data["password"].length < 1) {
    validationErrors.password = "Password is required";
  }
  return validationErrors;
};

import { takeLatest, all, call, put } from "typed-redux-saga";

import { apiRequest } from "lib/api/apiClient";
import { SHIPPING_ACTION_TYPES } from "./shipping.types";
import { shippingActions } from "./shipping.slice";
import { ActivateShipping } from "./shipping.actions";

export function* fetchData() {
  yield* put(shippingActions.setIsLoading(true));
  try {
    const link = "/api/v1/admin/country";
    const data = yield* call(apiRequest, link);
    if (!data) return;
    yield* put(shippingActions.setCountries(data));
  } catch (error) {
    console.log(error as Error);
  } finally {
    yield* put(shippingActions.setIsLoading(false));
  }
}
export function* ActivateData({ payload }: ActivateShipping) {
  yield* put(shippingActions.setIsLoading(true));
  try {
    const link = "/api/v1/admin/activate-shipping";
    const data = yield* call(apiRequest, link, {
      method: "POST",
      body: {
        ids: payload.data,
        is_active: payload.status ? 1 : 0,
        type: payload.type,
      },
    });
    if (!data) return;
    yield* put(shippingActions.setCountries(data));
  } catch (error) {
    console.log(error as Error);
  } finally {
    yield* put(shippingActions.setIsLoading(false));
  }
}

export function* onFetchData() {
  yield* takeLatest(SHIPPING_ACTION_TYPES.FETCH_ALL_DATA, fetchData);
}

export function* onActivate() {
  yield* takeLatest(SHIPPING_ACTION_TYPES.ACTIVATE_SHIPPING, ActivateData);
}

export function* shippingSagas() {
  yield* all([call(onFetchData), call(onActivate)]);
}

"use client";
import CheronRight from "assets/svgs/cheronright.svg";
import { PiUploadSimple } from "react-icons/pi";
import { useSelector } from "react-redux";
import { generatePdf } from "utils/pdfGenerator";
import { selectCustomers } from "store/users/users.selector";
import { Link } from "react-router-dom";

function CustomersPageHeader() {
  const users = useSelector(selectCustomers);

  const exportData = () => {
    const data =
      users == null
        ? []
        : users.map((user) => ({
            id: user.id,
            name: user.name,
            phone_number: user.phone_number,
            email: user.email,
            verified: user.email_verified,
          }));

    generatePdf("Cusorcart - Customers", data);
  };
  return (
    <div className="block justify-between page-header sm:flex">
      <div>
        <h3 className="text-dark-base hover:text-gray-900 dark:text-white dark:hover:text-white text-2xl font-medium">
          Users
        </h3>
        <ol className="flex items-center whitespace-nowrap min-w-0 mt-2">
          <li className="text-sm">
            <Link
              className="flex items-center text-gray-500 font-semibold hover:text-primary dark:text-gray-200 truncate"
              to="/">
              Dashboard
              <CheronRight className="flex-shrink-0 mx-3 overflow-visible text-gray-300 dark:text-gray-300 rtl:rotate-180" />
            </Link>
          </li>
          <li
            className="text-sm hover:text-primary dark:text-white/70 "
            aria-current="page">
            Customers
          </li>
        </ol>
      </div>
      <div className="flex items-center whitespace-nowrap min-w-0 gap-2">
        <button
          className="btn btn-outline"
          onClick={exportData}>
          <PiUploadSimple size={20} /> Export
        </button>
      </div>
    </div>
  );
}

export default CustomersPageHeader;

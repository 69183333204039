import FormSelect from "components/form/select.component";
import { selectProductLimit } from "store/product/product.selector";
import { supportActions } from "store/tickets/ticket.slice";
import { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";

function ProductRows() {
  const selected = useSelector(selectProductLimit);
  const sortOptions = [
    { value: 10, label: "10" },
    { value: 12, label: "12" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const dispatch = useDispatch();

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;

    dispatch(supportActions.setLimit(Number(value)));
  };
  return (
    <FormSelect
      label="Number of Rows"
      value={selected}
      options={sortOptions}
      onChange={handleChange}
    />
  );
}

export default ProductRows;

import CheronRight from "assets/svgs/cheronright.svg";
import { PiUploadSimple } from "react-icons/pi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectAllTickets } from "store/tickets/ticket.selector";
import { formatProductDate } from "utils/helper/helper";
import { generatePdf } from "utils/pdfGenerator";

function TicketPageHeader() {
  const tickets = useSelector(selectAllTickets);

  const exportData = () => {
    const data =
      tickets == null
        ? []
        : tickets.map((ticket, i) => ({
            no: i + 1,
            code: ticket.code,
            subject: ticket.subject,
            issue: ticket.issue.name,
            status: ticket.status,
            date: formatProductDate(ticket.created_at),
          }));

    generatePdf("Cusorcart - Tickets", data);
  };
  return (
    <div className="block justify-between page-header sm:flex">
      <div>
        <h3 className="text-dark-base hover:text-gray-900 dark:text-white dark:hover:text-white text-2xl font-medium">
          Tickets
        </h3>
        <ol className="flex items-center whitespace-nowrap min-w-0 mt-2">
          <li className="text-sm">
            <Link
              className="flex items-center text-gray-500 font-semibold hover:text-primary dark:text-gray-200 truncate"
              to="/">
              Dashboard
              <CheronRight className="flex-shrink-0 mx-3 overflow-visible text-gray-300 dark:text-gray-300 rtl:rotate-180" />
            </Link>
          </li>
          <li
            className="text-sm hover:text-primary dark:text-white/70 "
            aria-current="page">
            Support Tickets
          </li>
        </ol>
      </div>
      <div className="flex items-center whitespace-nowrap min-w-0 gap-2">
        <button
          className="btn btn-outline"
          onClick={exportData}>
          <PiUploadSimple size={20} /> Export
        </button>
      </div>
    </div>
  );
}

export default TicketPageHeader;

import FormSelect from "components/form/select.component";
import { selectSortOption } from "store/product/product.selector";
import { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { supportActions } from "store/tickets/ticket.slice";

function ProductSortBy() {
  const selected = useSelector(selectSortOption);
  const sortOptions = [
    { value: "date_asc", label: "Date (Oldest First)" },
    { value: "date_desc", label: "Date (Newest First)" },
    { value: "name_asc", label: "Name (A-Z)" },
    { value: "name_desc", label: "Name (Z-A)" },
  ];

  const dispatch = useDispatch();

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    dispatch(supportActions.setSortOption(value));
  };
  return (
    <FormSelect
      label="Sort By"
      value={selected}
      options={sortOptions}
      onChange={handleChange}
    />
  );
}

export default ProductSortBy;

import { Action, withMatcher, createAction } from "utils/reducer/reducer.utils";
import { Brands } from "utils/types/brand";
import { SUPPORT_ACTION_TYPES } from "./ticket.types";
import { supportActions } from "./ticket.slice";

export const setAllTickets = (value: Brands) =>
  supportActions.setAllTickets(value);

export const setBrandSearchValue = (value: string) =>
  supportActions.setSearchValue(value);

export type FetchAllData = Action<SUPPORT_ACTION_TYPES.FETCH_ALL_DATA>;

export const fetchAllTickets = withMatcher(
  (): FetchAllData => createAction(SUPPORT_ACTION_TYPES.FETCH_ALL_DATA)
);

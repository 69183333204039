import React, { useEffect, useState } from "react";
import MascotSvg from "./mascot";
// import PatternSvg from "assets/svgs/pattern.svg";

const Preloader: React.FC = () => {
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setToggle((prev) => !prev);
    }, 500); // Change colors every second

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="fixed flex bg-white dark:bg-dark-base items-center justify-center h-screen inset-0">
      {/* loader */}
      <div className="flex items-end space-x-6">
        <div className="relative w-20 h-20">
          <MascotSvg className={`w-20 h-20 absolute -bottom-4`} />
        </div>
        <div
          className={`w-4 h-4 rounded-full ${
            toggle ? "bg-secondary" : "bg-primary dark:bg-white"
          } transition-colors duration-500`}
        />
        <div
          className={`w-4 h-4 rounded-full ${
            toggle ? "bg-primary dark:bg-white" : "bg-secondary"
          } transition-colors duration-500`}
        />
      </div>
      {/* <PatternSvg className="absolute bottom-0 w-full inset-x-0 h-auto md:hidden lg:hidden" /> */}
    </div>
  );
};

export default Preloader;

import {
  ActionWithPayload,
  withMatcher,
  createAction,
  Action,
} from "utils/reducer/reducer.utils";
import { couponActions } from "./coupon.slice";
import { COUPON_ACTION_TYPES } from "./coupon.types";

type CouponData = {
  name: string;
  code: string;
  callback: () => void;
};
type DeleteCouponData = {
  uri: string;
};

type UpdateCouponData = {
  name: string;
  code: string;
  uri: string;
};

export type UpdateCoupon = ActionWithPayload<
  COUPON_ACTION_TYPES.UPDATE_COUPON,
  UpdateCouponData
>;
export type AddCoupon = ActionWithPayload<
  COUPON_ACTION_TYPES.ADD_COUPON,
  CouponData
>;
export type DeleteCoupon = ActionWithPayload<
  COUPON_ACTION_TYPES.DELETE_COUPON,
  DeleteCouponData
>;
export type FetchCoupons = Action<COUPON_ACTION_TYPES.FETCH_ALL_DATA>;

export const addCoupon = withMatcher(
  (formValues: CouponData): AddCoupon =>
    createAction(COUPON_ACTION_TYPES.ADD_COUPON, formValues)
);

export const updateCoupon = withMatcher(
  (formValues: UpdateCouponData): UpdateCoupon =>
    createAction(COUPON_ACTION_TYPES.UPDATE_COUPON, formValues)
);
export const deleteCoupon = withMatcher(
  (data: DeleteCouponData): DeleteCoupon =>
    createAction(COUPON_ACTION_TYPES.DELETE_COUPON, data)
);

export const getAllCoupon = withMatcher(
  (): FetchCoupons => createAction(COUPON_ACTION_TYPES.FETCH_ALL_DATA)
);

export const setCouponSearchValue = (value: string) =>
  couponActions.setSearchValue(value);

import CustomersPageHeader from "components/customers/page-header.component";
import CustomersList from "components/customers/users-table.component";
import DashboardLayout from "components/layouts/main-layout";
import Protected from "components/layouts/protected";

function CustomersPage() {
  return (
    <DashboardLayout name="customers">
      <main className="main-content">
        <Protected permissions={[8]}>
          <>
            <CustomersPageHeader />
            <CustomersList />
          </>
        </Protected>
      </main>
    </DashboardLayout>
  );
}

export default CustomersPage;

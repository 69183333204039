import { userActions } from "store/user/user.slice";
import { removeFromCookieProtected } from "utils/helper/helper";
import { AUTH_TOKEN } from "utils/helper/states";
import { Button } from "@nextui-org/react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

function NotPermitted() {
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(userActions.logout());
    removeFromCookieProtected(AUTH_TOKEN);
    // signOut();
  };
  return (
    <section className="dark:bg-primary-950 h-screen">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-secondary-400 dark:text-secondary-400">
            403
          </h1>
          <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
            {`Not Authorized to access this system`}
          </p>
          <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
            {`Sorry, you dont have permission to access this system. `}
          </p>
          <div className="flex items-center justify-center gap-4">
            <Button
              as={Link}
              href={"https://cusorcart.com"}
              color="secondary">
              Back to Main Site
            </Button>
            <Button
              onPress={logout}
              color="default">
              Sign in with another account
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NotPermitted;

import {
  Action,
  ActionWithPayload,
  withMatcher,
  createAction,
} from "utils/reducer/reducer.utils";
import { DATA_ACTION_TYPES } from "./data.types";
import { AppSettings } from "utils/types/app";
import {
  SearchItem,
  addToSearchHistory,
  setOverviewOption,
  setToggleSideBarSm,
} from "./data.slice";

export type FetchData = Action<DATA_ACTION_TYPES.FETCH_DATA>;

export type SetData = ActionWithPayload<
  DATA_ACTION_TYPES.SET_DATA,
  AppSettings
>;

export const fetchData = withMatcher(
  (): FetchData => createAction(DATA_ACTION_TYPES.FETCH_DATA)
);
export const setOverviewOptionFunc = (value: string) =>
  setOverviewOption(value);
export const addToSearches = (value: SearchItem) => addToSearchHistory(value);
export const toggleIsSideBarSm = () => setToggleSideBarSm();
// addToSearches;
// export const fetchData = () => createAction(DATA_ACTION_TYPES.FETCH_DATA);

export const setData = withMatcher(
  (data: AppSettings): SetData => createAction(DATA_ACTION_TYPES.SET_DATA, data)
);

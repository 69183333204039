"use client";

import { generateCombinations } from "utils/helper/helper";
import { useState } from "react";

const currencies = ["USD", "EUR", "NGN"],
  countries = ["Nigeria", "Netherlands", "United States"],
  combinations = generateCombinations(countries, currencies);

function LanguageCurrency() {
  const [currency, setCurrency] = useState("NGN"),
    [country, setCountry] = useState("Nigeria");
  const selected = combinations.find(
    (item) => item.currency === currency && item.country === country
  );
  const setData = (combination: { country: string; currency: string }) => {
    setCountry(combination.country);
    setCurrency(combination.currency);
  };
  const isActive = (combination: { country: string; currency: string }) =>
    country === combination.country && currency === combination.currency;
  return (
    <div className="dropdown dropdown-end hidden lg:block">
      <div
        tabIndex={0}
        role="button"
        className="btn btn-ghost rounded-btn">
        {selected == null
          ? "Country - Currency"
          : `${selected.country}-${selected.currency}`}
      </div>
      <ul
        tabIndex={0}
        className="menu dropdown-content p-2 shadow bg-white dark:bg-primary-950 rounded-box w-52 mt-4 z-10">
        {combinations.map((item, i) => (
          <li key={i}>
            <span
              role="button"
              onClick={() => setData(item)}
              className={isActive(item) ? "bg-secondary-400" : ""}>
              {item.country}-{item.currency}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default LanguageCurrency;

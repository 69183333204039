import { SvgProps } from "utils/types/app";

const LogoSvg: React.FC<SvgProps> = (props) => {
  return (
    <svg
      width={924}
      height={189}
      viewBox="0 0 924 189"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_6_90)">
        <path
          d="M114.721 102.952C114.721 117.468 114.692 131.049 114.76 144.64C114.769 146.558 114.03 147.493 112.339 148.262C57.1457 173.536 3.7999 138.984 0.611011 84.7069C-1.17788 54.1951 9.01101 28.9309 34.7846 11.9031C59.4596 -4.40432 85.8166 -3.06078 112.232 8.25215C113.642 8.85577 114.74 9.52753 114.73 11.3676C114.682 25.1047 114.711 38.8321 114.711 53.2118C110.919 49.9698 107.769 47.0491 104.376 44.4399C91.9902 34.9281 78.2332 33.1854 64.1263 39.2605C50.0777 45.3064 43.2916 57.2327 41.6096 72.0699C39.7624 88.3481 43.5541 102.786 57.1652 113.184C71.4082 124.069 90.9693 123.027 106.068 110.857C108.819 108.647 111.299 106.096 114.721 102.961V102.952Z"
          fill="currentColor"
        />
        <path
          d="M615.524 103.546C616.856 105.045 616.02 106.739 616.029 108.248C616.107 120.087 616.01 131.925 616.117 143.764C616.136 145.886 615.971 147.347 613.725 148.369C580.922 163.187 538.436 159.955 514.024 122.667C494.054 92.1645 494.433 35.3273 540.351 9.32309C564.219 -4.19986 589.079 -2.4669 613.706 8.28137C615.456 9.04075 616.136 10.0727 616.126 11.9517C616.078 25.5331 616.097 39.1047 616.097 53.4163C612.617 50.3203 609.797 47.5651 606.715 45.1214C594.368 35.337 580.553 33.0394 566.096 38.9976C551.308 45.0922 544.493 57.5053 542.86 72.8683C541.742 83.3732 543.064 93.586 548.907 102.796C561.351 122.414 587.174 126.551 606.2 111.86C609.467 109.338 612.393 106.359 615.524 103.546Z"
          fill="currentColor"
        />
        <path
          d="M134.146 84.853C134.146 74.6402 134.243 64.4177 134.078 54.2146C134.029 51.0408 134.593 49.5999 138.249 49.707C147.145 49.9698 156.06 49.9211 164.956 49.707C168.184 49.6291 169 50.6221 168.971 53.8154C168.815 70.6778 168.922 87.5401 168.913 104.402C168.913 108.472 169.156 112.512 170.147 116.465C172.315 125.178 177.877 129.316 187.142 129.209C196.835 129.102 202.046 124.906 204.049 115.813C204.856 112.133 205.011 108.433 205.011 104.704C205.011 87.8419 205.099 70.9796 204.953 54.1173C204.924 50.8363 205.604 49.6096 209.163 49.707C218.389 49.9601 227.615 49.8141 236.842 49.7459C239.185 49.7264 240.789 49.7848 240.731 53.0074C240.322 73.5887 241.489 94.1896 240.05 114.751C238.475 137.202 226.984 150.423 204.875 154.989C190.389 157.978 175.845 157.822 161.796 152.624C145.754 146.694 136.528 135.226 134.934 117.906C133.913 106.904 134.243 95.8739 134.156 84.853H134.146Z"
          fill="currentColor"
        />
        <path
          d="M264.55 116.514C272.843 123.183 281.564 127.934 291.646 129.822C296.293 130.689 300.969 131.039 305.655 130.407C310.001 129.822 314.192 128.605 314.93 123.426C315.679 118.14 311.722 115.92 307.755 114.343C304.012 112.863 300.017 112.006 296.118 110.964C290.644 109.504 285.278 107.781 280.105 105.405C268.604 100.119 261.847 91.7848 262.236 78.5734C262.625 65.07 269.596 56.006 281.515 50.7389C293.833 45.2869 306.803 45.8516 319.733 47.9837C327.258 49.2299 334.492 51.5957 341.579 54.4191C344.078 55.4121 345.186 56.3175 344.107 59.3259C341.929 65.4205 340.024 71.6416 338.468 77.9211C337.68 81.0853 336.562 80.394 334.647 79.216C326.364 74.1242 317.478 70.9114 307.619 70.9893C300.444 71.038 295.574 73.4622 295.554 77.5415C295.525 83.6458 300.853 84.8043 305.14 86.2744C312.928 88.942 320.997 90.7334 328.629 93.9949C340.315 98.999 347.889 106.739 348.151 120.262C348.433 134.408 341.978 144.407 329.854 151.017C324.837 153.753 319.412 155.418 313.725 156.041C295.068 158.056 277.296 155.272 260.593 146.324C257.822 144.845 256.976 143.472 257.958 140.308C260.33 132.626 262.275 124.818 264.54 116.514H264.55Z"
          fill="currentColor"
        />
        <path
          d="M406.232 50.3106C412.921 52.9879 419.658 55.5484 426.289 58.3815C430.372 60.1242 426.707 62.5874 425.91 63.5317C421.165 69.1298 416.003 74.3676 410.977 79.732C407.263 81.1047 408.488 76.9963 406.893 76.0324C406.329 75.9935 405.765 75.9545 405.211 75.9156C403.87 76.4803 402.256 76.4608 401.245 77.7362C388.567 86.0797 385.903 98.4344 387.663 112.395C388.917 122.316 399.456 133.084 410.121 135.79C420.076 138.312 432.55 133.123 438.724 123.884C439.881 122.151 441.067 120.437 442.243 118.704C443.342 117.585 443.381 116.027 443.945 114.683C445.695 108.229 445.656 101.783 443.974 95.3287L443.993 95.3481C442.768 94.2967 443.381 92.4566 442.263 91.3565C441.67 90.2271 441.29 88.8836 440.435 88.0171C435.982 83.5387 437.508 79.1089 439.793 74.1729C444.392 64.2035 449.651 54.8085 457.546 46.9907C461.736 42.8433 466.471 39.611 471.663 36.9921C474.346 35.6486 476.757 35.707 477.302 39.5331C477.37 40.0102 477.583 40.458 477.729 40.9253C478.847 44.4497 480.763 47.9545 480.899 51.5178C481.074 56.0157 475.697 55.821 473.199 58.2939C468.27 63.1812 463.807 68.2828 460.871 74.6792C459.976 76.6263 459.763 77.7849 461.046 79.8294C470.457 94.8321 469.981 117.731 459.014 132.792C446.57 149.888 429.653 157.647 408.945 155.087C383.939 152 364.388 130.348 364.154 105.094C363.94 81.2703 381.489 61.1075 401.215 56.2883C402.158 55.4511 402.353 54.3607 402.12 53.2118C401.264 49.0157 403.549 49.3662 406.251 50.3008L406.232 50.3106Z"
          fill="#FFA815"
        />
        <path
          d="M872.793 113.778C872.793 102.426 872.706 91.0839 872.861 79.732C872.9 76.7042 872.356 75.3704 868.933 75.5359C865.288 75.7111 860.485 77.2104 858.219 74.8057C855.993 72.4399 857.704 67.6986 857.52 64.0088C857.5 63.6875 857.52 63.3565 857.52 63.0352C857.607 58.8391 856.197 53.572 858.2 50.7389C860.174 47.9351 865.745 49.9114 869.731 49.8725C872.56 49.8433 872.822 48.5387 872.803 46.28C872.735 38.3356 872.89 30.3912 872.754 22.4469C872.706 19.9058 873.454 19.1562 876.011 19.1951C885.889 19.3412 895.757 19.3217 905.635 19.1951C907.988 19.1659 908.629 19.828 908.581 22.1743C908.425 30.1186 908.649 38.063 908.464 46.0074C908.396 48.9768 909.29 49.8627 912.275 49.8919C916.067 49.9211 921.132 47.9935 923.319 50.7876C925.147 53.1339 923.845 57.9434 923.883 61.6722C923.922 65.2355 923.757 68.8085 923.932 72.3718C924.058 74.8544 923.339 76.0811 920.714 75.6722C920.558 75.643 920.393 75.6527 920.228 75.6722C916.446 76.0714 911.419 73.8321 909.213 76.7139C907.385 79.1089 908.551 83.86 908.542 87.5595C908.513 107.82 908.415 128.089 908.62 148.35C908.658 152.108 907.54 152.916 904.011 152.828C894.95 152.585 885.879 152.624 876.818 152.828C873.697 152.896 872.638 152.098 872.686 148.817C872.881 137.144 872.764 125.471 872.774 113.797L872.793 113.778Z"
          fill="currentColor"
        />
        <path
          d="M808.461 72.7515C811.115 68.4093 812.758 65.2452 814.839 62.3926C823.9 49.9212 836.655 46.5234 851.258 47.789C853.319 47.9643 853.98 48.9184 853.747 50.9726C852.707 60.1437 851.686 69.3245 850.85 78.515C850.578 81.5331 849.061 80.7835 847.214 80.3551C838.785 78.3787 830.423 78.2716 822.782 82.9545C812.748 89.0978 810.522 99.1451 810.376 109.893C810.201 122.861 810.23 135.829 810.425 148.797C810.474 152.059 809.482 152.857 806.351 152.799C796.804 152.604 787.247 152.643 777.7 152.779C774.978 152.818 774.122 152.059 774.132 149.255C774.229 117.322 774.239 85.3787 774.112 53.4455C774.103 50.2716 775.23 49.6972 778.04 49.7362C786.946 49.8822 795.851 49.9017 804.747 49.707C807.81 49.6388 808.617 50.6708 808.51 53.6013C808.296 59.5206 808.451 65.4497 808.451 72.7612L808.461 72.7515Z"
          fill="currentColor"
        />
        <path
          d="M455.611 176.398C455.582 183.525 450.021 188.383 442.564 188.889C436.507 189.298 429.225 183.593 429.507 175.318C429.711 169.272 433.833 162.866 443.079 162.739C450.031 162.651 455.64 168.746 455.611 176.398Z"
          fill="#FFA815"
        />
        <path
          d="M390.414 188.772C382.772 188.792 377.619 183.515 377.609 175.668C377.6 167.928 382.48 163.089 390.355 163.041C398.055 162.992 403.383 168.025 403.402 175.386C403.432 183.632 398.464 188.753 390.414 188.772Z"
          fill="#FFA815"
        />
        <path
          d="M746.831 53.2508C746.831 50.369 745.869 49.6972 743.205 49.7362C734.299 49.8627 725.394 49.9017 716.488 49.7167C713.552 49.6583 712.939 50.7876 713.066 53.4065C713.251 57.2035 713.114 61.0101 713.114 66.1019C709.264 60.7278 705.949 56.6972 701.759 53.4163C688.332 42.8919 656.346 42.5414 641.316 65.0213C629.474 82.7306 628.969 102.241 633.878 122.034C638.156 139.247 648.306 151.864 666.457 155.612C684.862 159.409 700.378 154.23 711.705 138.555C711.977 138.185 712.084 137.689 712.259 137.26C712.628 137.494 713.007 137.728 713.377 137.971C713.377 141.69 713.494 145.419 713.338 149.128C713.231 151.65 713.698 152.886 716.692 152.818C725.588 152.614 734.503 152.624 743.409 152.808C746.364 152.867 746.831 151.679 746.831 149.089C746.753 117.146 746.734 85.2035 746.831 53.2702V53.2508ZM703.023 124.137C694.807 128.43 686.126 128.372 677.949 123.562C667.459 117.4 663.755 100.07 670.016 87.3356C675.276 76.6458 688.663 72.0213 700.524 76.8113C709.702 80.5108 714.787 89.1951 714.796 102.932C714.689 110.789 712.055 119.405 703.023 124.127V124.137Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_6_90">
          <rect
            width={924}
            height={189}
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoSvg;

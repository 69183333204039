import React from "react";
import { classNames } from "utils/helper/helper";

interface ChatMessageProps {
  message: string;
  timestamp: string;
  isSender?: boolean;
  isAdmin?: boolean;
  senderName?: string;
}

const ChatMessage: React.FC<ChatMessageProps> = ({
  message,
  timestamp,
  isSender = false,
  isAdmin = false,
  senderName,
}) => {
  return (
    <div
      className={classNames(
        "mb-4 flex",
        isAdmin ? "justify-end" : "justify-start"
      )}>
      <div className="space-y-4">
        <div
          className={classNames(
            `rounded-lg p-4 max-w-xs shadow`,
            isAdmin
              ? "bg-primary dark:bg-primary-900 text-white justify-end rounded-br-none"
              : "justify-start bg-[#f5f7fb] border border-[#a1a4b6] rounded-bl-none"
          )}>
          <p className="text-sm mb-2">{message}</p>
        </div>
        <div className="text-xs text-right text-gray-500">
          {timestamp} {!isSender && senderName && `(${senderName})`}
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;

import { lazy } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, RouteObject } from "react-router-dom";
import { selectCurrentUser, selectIsLoggedIn } from "store/user/user.selector";
import { REDIRECT_URI } from "utils/helper/states";

const LoginPage = lazy(() => import("./login"));

function Authentication() {
  const isLoggedIn = useSelector(selectIsLoggedIn),
    user = useSelector(selectCurrentUser);
  const redirect = sessionStorage.getItem(REDIRECT_URI);
  const redirectURI = redirect == null ? "/" : redirect;
  if (isLoggedIn && user != null) {
    if (user.type === "admin") {
      return (
        <Navigate
          to={redirectURI}
          replace
        />
      );
    }
  }
  return isLoggedIn ? (
    <Navigate
      to={redirectURI}
      replace
    />
  ) : (
    <>
      <Outlet />
    </>
  );
}

export const authRoutes: RouteObject[] = [
  {
    path: "/auth/login",
    element: <LoginPage />,
  },
];

export default Authentication;

import { Blog, Topic } from "utils/types/blog";
import { Brands } from "utils/types/brand";
import { createSlice } from "@reduxjs/toolkit";

export type BrandState = {
  readonly all: Blog[] | null;
  readonly topics: Topic[] | null;
  searchValue: "";
  loading: boolean;
};

const INITIAL_STATE: BrandState = {
  all: null,
  topics: null,
  searchValue: "",
  loading: false,
};
const blogSlice = createSlice({
  name: "blog",
  initialState: INITIAL_STATE,
  reducers: {
    setAllBlog(state, action) {
      state.all = action.payload;
    },
    setAllTopics(state, action) {
      state.topics = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setIsLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const blogActions = blogSlice.actions;
export default blogSlice;

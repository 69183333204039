import { createSlice } from "@reduxjs/toolkit";
import { Banner, BannerPosition } from "utils/types/marketing";

export type MarketingState = {
  readonly bannerPositions: BannerPosition[] | null;
  readonly banners: Banner[] | null;
  readonly banner: Banner | null;
  searchValue: "";
  loading: boolean;
};

const INITIAL_STATE: MarketingState = {
  bannerPositions: null,
  banners: null,
  banner: null,
  searchValue: "",
  loading: false,
};
const marketingSlice = createSlice({
  name: "marketing",
  initialState: INITIAL_STATE,
  reducers: {
    setBannerPositions(state, action) {
      state.bannerPositions = action.payload;
    },
    setBanners(state, action) {
      state.banners = action.payload;
    },
    setBanner(state, action) {
      state.banner = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setIsLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const marketingActions = marketingSlice.actions;
export default marketingSlice;

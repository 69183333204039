import { compose } from "redux";
import {
  persistStore,
  persistReducer,
  PersistConfig,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { rootReducer } from "./root-reducer";
// import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "./root-saga";
import { configureStore } from "@reduxjs/toolkit";
import { STAFF_ACTION_TYPES } from "./staff/staff.types";
import { ATTRIBUTE_ACTION_TYPES } from "./attribute/attribute.types";
import { PRODUCT_ACTION_TYPES } from "./product/product.types";

export type RootState = ReturnType<typeof rootReducer>;

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

type ExtendedPersistConfig = PersistConfig<RootState> & {
  whitelist: (keyof RootState)[];
};

const persistConfig: ExtendedPersistConfig = {
  key: "root",
  storage,
  whitelist: [
    "data",
    "user",
    "category",
    "brand",
    "staff",
    "attribute",
    "color",
    "users",
    "blog",
    "theme",
    "coupons",
    "policy",
    "support",
    "activity",
    "shipping",
  ],
};

const sagaMiddleware = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, rootReducer);

// const useDevtool = process.env.NODE_ENV !== "production";

export const store = configureStore({
  reducer: persistedReducer, // Replace rootReducer with your actual rootReducer
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          STAFF_ACTION_TYPES.ADD_ROLE,
          STAFF_ACTION_TYPES.ADD_STAFF,
          ATTRIBUTE_ACTION_TYPES.ADD_ATTRIBUTE_VALUE,
          ATTRIBUTE_ACTION_TYPES.ADD_ATTRIBUTE,
          PRODUCT_ACTION_TYPES.UPLOAD_PRODUCT,
        ],
      },
    }).concat(sagaMiddleware),
  devTools: true,
});

sagaMiddleware.run(rootSaga);
export const persistor = persistStore(store);

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

import { Action, withMatcher, createAction } from "utils/reducer/reducer.utils";
import { Brands } from "utils/types/brand";
import { brandActions } from "./brand.slice";
import { BRAND_ACTION_TYPES } from "./brand.types";

export const setAllBrand = (value: Brands) => brandActions.setAllBrand(value);

export const setBrandSearchValue = (value: string) =>
  brandActions.setSearchValue(value);

export type FetchAllData = Action<BRAND_ACTION_TYPES.FETCH_ALL_DATA>;

export const fetchAllBrand = withMatcher(
  (): FetchAllData => createAction(BRAND_ACTION_TYPES.FETCH_ALL_DATA)
);

import { Categories } from "utils/types/category";
import { createSlice } from "@reduxjs/toolkit";

export type CategoryState = {
  readonly all: Categories | null;
  searchValue: "";
  loading: boolean;
  start_date: string | null;
  end_date: string | null;
  sortOption: string;
  limit: number;
};

const INITIAL_STATE: CategoryState = {
  all: null,
  searchValue: "",
  loading: false,
  start_date: null,
  end_date: null,
  sortOption: "date_desc",
  limit: 10,
};
const categorySlice = createSlice({
  name: "category",
  initialState: INITIAL_STATE,
  reducers: {
    setAllCategory(state, action) {
      state.all = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setIsLoading(state, action) {
      state.loading = action.payload;
    },
    setStartDate(state, action) {
      state.start_date = action.payload;
    },
    setEndDate(state, action) {
      state.end_date = action.payload;
    },
    setSortOption(state, action) {
      state.sortOption = action.payload;
    },
    setLimit(state, action) {
      state.limit = action.payload;
    },
  },
});

export const categoryActions = categorySlice.actions;
export default categorySlice;

import { Brands } from "utils/types/brand";
import { createSlice } from "@reduxjs/toolkit";

export type BrandState = {
  readonly all: Brands | null;
  searchValue: "";
  loading: boolean;
};

const INITIAL_STATE: BrandState = {
  all: null,
  searchValue: "",
  loading: false,
};
const brandSlice = createSlice({
  name: "brand",
  initialState: INITIAL_STATE,
  reducers: {
    setAllBrand(state, action) {
      state.all = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setIsLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const brandActions = brandSlice.actions;
export default brandSlice;

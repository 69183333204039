function FilterSvg({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.9163 6.24967H10.8043C10.5988 4.60592 9.19926 3.33301 7.49967 3.33301C5.80009 3.33301 4.40051 4.60592 4.19509 6.24967H2.08301V7.08301H4.19509C4.40051 8.72676 5.80009 9.99967 7.49967 9.99967C9.19926 9.99967 10.5988 8.72676 10.8043 7.08301H17.9163V6.24967ZM7.49967 9.16634C6.12134 9.16634 4.99967 8.04467 4.99967 6.66634C4.99967 5.28801 6.12134 4.16634 7.49967 4.16634C8.87801 4.16634 9.99967 5.28801 9.99967 6.66634C9.99967 8.04467 8.87801 9.16634 7.49967 9.16634Z"
        fill="currentColor"
      />
      <path
        d="M17.9163 12.9167H15.8043C15.5988 11.2729 14.1993 10 12.4997 10C10.8001 10 9.40051 11.2729 9.19509 12.9167H2.08301V13.75H9.19509C9.40051 15.3937 10.8001 16.6667 12.4997 16.6667C14.1993 16.6667 15.5988 15.3937 15.8043 13.75H17.9163V12.9167ZM12.4997 15.8333C11.1213 15.8333 9.99967 14.7117 9.99967 13.3333C9.99967 11.955 11.1213 10.8333 12.4997 10.8333C13.878 10.8333 14.9997 11.955 14.9997 13.3333C14.9997 14.7117 13.878 15.8333 12.4997 15.8333Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default FilterSvg;

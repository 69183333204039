function SupportSvg({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width={26}
      height={26}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.50879 14.3177L11.8838 23.6927L23.3421 12.2344V2.85938H13.9671L2.50879 14.3177ZM22.3005 3.90104V11.8031L11.8838 22.2198L3.98171 14.3177L14.3984 3.90104H22.3005Z"
        fill="currentColor"
      />
      <path
        d="M17.0924 10.1517C17.6677 10.1517 18.1341 9.68532 18.1341 9.11003C18.1341 8.53473 17.6677 8.06836 17.0924 8.06836C16.5172 8.06836 16.0508 8.53473 16.0508 9.11003C16.0508 9.68532 16.5172 10.1517 17.0924 10.1517Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SupportSvg;

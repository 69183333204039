import { createSlice } from "@reduxjs/toolkit";
import { Policy } from "utils/types/policies";

export type PolicyState = {
  readonly all: Policy[] | null;
  searchValue: "";
  loading: boolean;
};

const INITIAL_STATE: PolicyState = {
  all: null,
  searchValue: "",
  loading: false,
};
const policySlice = createSlice({
  name: "policy",
  initialState: INITIAL_STATE,
  reducers: {
    setAllPolicy(state, action) {
      state.all = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setIsLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const policyActions = policySlice.actions;
export default policySlice;

import {
  ActionWithPayload,
  withMatcher,
  createAction,
  Action,
} from "utils/reducer/reducer.utils";
import { PRODUCT_ACTION_TYPES } from "./product.types";
import { ProductUpdatePayload } from "./actions";
import { Attribute } from "utils/types/attribute";
import { Product } from "utils/types/products";
import { productActions } from "./product.slice";
import { Category } from "utils/types/category";

type ProductData = {
  product: Product;
  callback: () => void;
};
type EditProductData = {
  product: Product;
  action: string;
};
export type UpdateCurrentProduct = ActionWithPayload<
  PRODUCT_ACTION_TYPES.UPDATE_PRODUCT,
  ProductUpdatePayload
>;
export type ProductAttrProps = {
  type: "update" | "delete";
  value: Attribute;
};
export type UpdateCurrentProductAttr = ActionWithPayload<
  PRODUCT_ACTION_TYPES.UPDATE_PRODUCT_ATTRIBUTES,
  ProductAttrProps
>;
export type UploadProductAttr = ActionWithPayload<
  PRODUCT_ACTION_TYPES.UPLOAD_PRODUCT,
  ProductData
>;
export type UploadEditProductAttr = ActionWithPayload<
  PRODUCT_ACTION_TYPES.UPDATE_EDIT_PRODUCT,
  EditProductData
>;
export type DeleteMultipleProducts = ActionWithPayload<
  PRODUCT_ACTION_TYPES.DELETE_PRODUCTS,
  number[]
>;

export type GetRelatedCategoryData = ActionWithPayload<
  PRODUCT_ACTION_TYPES.RELATED_CATEGORY_DATA,
  Category
>;

export type FetchProducts = Action<PRODUCT_ACTION_TYPES.FETCH_ALL_DATA>;

export const getAllProducts = withMatcher(
  (): FetchProducts => createAction(PRODUCT_ACTION_TYPES.FETCH_ALL_DATA)
);
export const updateCurrentProduct = withMatcher(
  (data: ProductUpdatePayload): UpdateCurrentProduct =>
    createAction(PRODUCT_ACTION_TYPES.UPDATE_PRODUCT, data)
);
export const getRelatedCategoryData = withMatcher(
  (data: Category): GetRelatedCategoryData =>
    createAction(PRODUCT_ACTION_TYPES.RELATED_CATEGORY_DATA, data)
);
export const updateProductAttributes = withMatcher(
  (data: ProductAttrProps): UpdateCurrentProductAttr =>
    createAction(PRODUCT_ACTION_TYPES.UPDATE_PRODUCT_ATTRIBUTES, data)
);

export const uploadProduct = withMatcher(
  (data: ProductData): UploadProductAttr =>
    createAction(PRODUCT_ACTION_TYPES.UPLOAD_PRODUCT, data)
);
export const uploadEditProduct = withMatcher(
  (data: EditProductData): UploadEditProductAttr =>
    createAction(PRODUCT_ACTION_TYPES.UPDATE_EDIT_PRODUCT, data)
);
export const setProductSearchValue = (value: string) =>
  productActions.setSearchValue(value);

export const deleteMultipleProducts = withMatcher(
  (data: number[]): DeleteMultipleProducts =>
    createAction(PRODUCT_ACTION_TYPES.DELETE_PRODUCTS, data)
);

export enum ATTRIBUTE_ACTION_TYPES {
  FETCH_ALL_DATA = "attribute/FETCH_ALL_DATA",
  UPDATE_ATTRIBUTE = "attribute/UPDATE_ATTRIBUTE",
  ADD_ATTRIBUTE = "attribute/ADD_ATTRIBUTE",
  DELETE_ATTRIBUTE = "attribute/DELETE_ATTRIBUTE",
  ADD_ATTRIBUTE_VALUE = "attribute/ADD_ATTRIBUTE_VALUE",
  DELETE_ATTRIBUTE_VALUE = "attribute/DELETE_ATTRIBUTE_VALUE",
  UPDATE_ATTRIBUTE_VALUE = "attribute/UPDATE_ATTRIBUTE_VALUE",
  SET_DATA = "attribute/SET_DATA",
  SET_IS_LOADING = "attribute/SET_IS_LOADING",
}

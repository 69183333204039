"use client";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAttributes } from "store/attribute/attribute.actions";
import { fetchAllBrand } from "store/brand/brand.actions";
import { fetchAllCategory } from "store/category/category.actions";
import {
  getEmails,
  getPermissions,
  getRoles,
  getStaffs,
} from "store/staff/staff.actions";
import { getAllColor } from "store/color/color.actions";
import { getAllProducts } from "store/product/product.actions";
import { fetchCustomers, fetchVendors } from "store/users/users.actions";
import { fetchAllBlog } from "store/blog/blog.actions";
import { getAllCoupon } from "store/coupon/coupon.actions";
import { fetchAllPolicy } from "store/policy/policy.actions";
import { fetchAllTickets } from "store/tickets/ticket.actions";
import { fetchAllActivities } from "store/activity/activity.actions";
import { fetchAllCountries } from "store/shipping/shipping.actions";
import { fetchAllMarketingData } from "store/marketing/marketing.actions";

type DispatcherProps = {
  client?: string;
};

function Dispatcher({ client = "" }: DispatcherProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    const runDispatcher = (client: string) => {
      switch (client) {
        case "categories":
          dispatch(fetchAllCategory());
          break;
        case "brands":
          dispatch(fetchAllBrand());
          break;
        case "permissions":
          dispatch(getRoles());
          dispatch(getPermissions());
          break;
        case "roles":
          dispatch(getRoles());
          dispatch(getPermissions());
          break;
        case "support":
          dispatch(getStaffs());
          dispatch(fetchAllTickets());
          break;
        case "staff":
          dispatch(getEmails());
          dispatch(getStaffs());
          dispatch(getRoles());
          dispatch(getPermissions());
          break;
        case "attributes":
          dispatch(getAttributes());
          break;
        case "emails":
          dispatch(getEmails());
          break;
        case "colors":
          dispatch(getAllColor());
          break;
        case "coupons":
          dispatch(getAllCoupon());
          break;
        case "vendors":
          dispatch(fetchVendors());
          break;
        case "customers":
          dispatch(fetchCustomers());
          break;
        case "shipping":
          dispatch(fetchAllCountries());
          break;
        case "marketing":
          dispatch(fetchAllMarketingData());
          break;
        case "home":
          dispatch(fetchCustomers());
          dispatch(fetchVendors());
          break;
        case "blog":
          dispatch(fetchAllBlog());
          break;
        case "policy":
          dispatch(fetchAllPolicy());
          break;
        case "products":
          dispatch(getAllColor());
          dispatch(getAllProducts());
          dispatch(fetchAllCategory());
          dispatch(fetchAllBrand());
          dispatch(getAttributes());
          dispatch(getAllCoupon());
          break;
        case "all":
          dispatch(fetchAllCategory());
          dispatch(fetchAllBrand());
          dispatch(getPermissions());
          dispatch(getRoles());
          dispatch(getAttributes());
          break;

        default:
          break;
      }
    };

    dispatch(fetchAllActivities());
    runDispatcher(client);
  }, [client, dispatch]);

  return null;
}

export default Dispatcher;

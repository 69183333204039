import { createSelector } from "reselect";
import { RootState } from "../store";

const selectStaffReducer = (state: RootState) => state.staff;

export const selectPermissions = createSelector(
  [selectStaffReducer],
  (data) => data.permissions
);
export const selectRoles = createSelector(
  [selectStaffReducer],
  (data) => data.roles
);

export const selectStaff = createSelector(
  [selectStaffReducer],
  (data) => data.users
);
export const selectEmails = createSelector(
  [selectStaffReducer],
  (data) => data.emails
);

export const selectStaffLoading = createSelector(
  [selectStaffReducer],
  (data) => data.loading
);

import DashboardLayout from "components/layouts/main-layout";
import React from "react";

const ComingSoon = () => {
  return (
    <DashboardLayout name="all">
      <main className="main-content">
        <div className="flex items-center justify-center h-screen">
          <div className="text-center p-8 max-w-lg w-full">
            <h1 className="text-5xl font-bold text-primary dark:text-white mb-4">
              Coming Soon
            </h1>
            <p className="text-lg text-gray-600 dark:text-gray-300 mb-6">
              We are working hard to bring you something amazing. Stay tuned!
            </p>

            <p className="text-sm text-gray-500 dark:text-gray-200 mt-4">
              Reach out to IT for help if needed.
            </p>
          </div>
        </div>
      </main>
    </DashboardLayout>
  );
};

export default ComingSoon;

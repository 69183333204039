import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { TDocumentDefinitions, Table, ContentTable } from "pdfmake/interfaces";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

type DataRow = {
  [key: string]: any;
};

export const generatePdf = (
  title: string,
  data: DataRow[],
  columnWidth = "auto"
): void => {
  if (data.length === 0) return;

  const headers = Object.keys(data[0]);

  const body: (string | number)[][] = [
    headers,
    ...data.map((item) => headers.map((header) => item[header])),
  ];

  // Set up column widths: 'auto' for automatic adjustment, or specific values (e.g., '100px', '*')
  const columnWidths = headers.map((header) => columnWidth);

  const tableContent: ContentTable = {
    table: {
      headerRows: 1,
      widths: columnWidths,
      body: body,
    },
    layout: "lightHorizontalLines",
  };

  const docDefinition: TDocumentDefinitions = {
    content: [{ text: title, style: "header" }, tableContent],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
    },
    pageSize: "A4",
    pageMargins: [40, 60, 40, 60],
    defaultStyle: {
      fontSize: 10,
    },
  };

  // Get current timestamp
  const now = new Date();
  const timestamp = now.toISOString().replace(/[:.]/g, "-");
  pdfMake.createPdf(docDefinition).download(`${title} - ${timestamp}.pdf`);
};

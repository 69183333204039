import { selectCurrentUser } from "store/user/user.selector";
import { Spinner } from "@nextui-org/react";
import { useSelector } from "react-redux";
import NotPermitted from "routes/forbidden/page";

function Protected({
  children,
  permissions,
}: {
  children: JSX.Element;
  permissions: number[];
}): JSX.Element {
  const user = useSelector(selectCurrentUser);
  const hasPermission =
    permissions.length === 0 || // No permissions required
    permissions.some((permission) =>
      user?.role?.permissions.includes(permission)
    );
  if (user == null)
    return (
      <div className="w-full h-full centered">
        <Spinner size="lg" />
      </div>
    );
  return hasPermission || user?.type === "admin" ? children : <NotPermitted />;
}

export default Protected;

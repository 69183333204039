import { takeLatest, all, call, put } from "typed-redux-saga";

import {
  CompleteRegistration,
  signInFailed,
  signOutFailed,
  signOutStart,
  signOutSuccess,
  SignUpSuccess,
} from "./user.action";

import { USER_ACTION_TYPES } from "./user.types";
import { userActions } from "./user.slice";
import { AccessToken, User } from "utils/types/user";
import { getEnvProps } from "utils/helper/server-helper";
import { alertMessage } from "components/toolkit/initial-state.component";
import { getHeaders } from "utils/header";
import { AUTH_TOKEN } from "utils/helper/states";
import { getFromCookieProtected } from "utils/helper/helper";

export function* getSnapshotFromUserAuth(
  user: User
  //additionalInformation
) {
  try {
    yield* put(userActions.setCurrentUser(user));
    yield* put(userActions.setIsLoggedIn(true));
  } catch (error) {
    yield* put(signInFailed(error as Error));
  } finally {
    yield* put(userActions.setIsLoading(false));
  }
}

export function* signInAfterSignUp({ payload: user }: SignUpSuccess) {
  try {
    yield* call(getSnapshotFromUserAuth, user);
  } catch (error) {
    yield* put(signInFailed(error as Error));
  }
}

async function getCurrentUser() {
  const envProps = await getEnvProps();
  const hostURL = envProps.base_url,
    apiKey = envProps.apiKey;

  if (apiKey == null) return;
  const headers = await getHeaders();

  const req = await fetch(hostURL + "/api/v1/auth/user", {
    headers,
  });
  const res = await req.json();

  if (res.success != null) {
    if (res.success) return await res.data;
  }
}

export function* isUserAuthenticated() {
  try {
    const token = getFromCookieProtected<AccessToken>(AUTH_TOKEN);
    if (token == null) {
      yield* put(signOutStart());
      return;
    }
    const userAuth = yield* call(getCurrentUser);

    if (userAuth == null) return;
    yield* call(getSnapshotFromUserAuth, userAuth);
  } catch (error) {
    yield* put(signInFailed(error as Error));
  }
}

export function* signOut() {
  try {
    // yield* call(signOutUser);
    yield* put(userActions.logout());
    localStorage.removeItem(AUTH_TOKEN);
    yield* put(signOutSuccess());
  } catch (error) {
    yield* put(signOutFailed(error as Error));
  }
}

const submitCompleteRegistration = async (
  formData: FormData,
  access_token: AccessToken
) => {
  const envProps = await getEnvProps();
  const hostURL = envProps.base_url,
    apiKey = envProps.apiKey;

  if (apiKey == null) return;
  const headers = await getHeaders(access_token, true);
  const response = await fetch(
    hostURL! + "/api/v1/auth/complete-registration",
    {
      method: "POST",
      headers,
      body: formData,
    }
  );
  const result = await response.json();
  return result;
};

export function* updateCompleteRegistration({
  payload: data,
}: CompleteRegistration) {
  yield* put(userActions.setIsLoading(true));
  try {
    const req = yield* call(
      submitCompleteRegistration,
      data.formData,
      data.token
    );
    if (req.errors != null) {
      alertMessage("warn", req.message);
    } else {
      if (req.user != null) {
        yield* put(userActions.setCurrentUser(req.user));
      }
    }
  } catch (error) {
  } finally {
    yield* put(userActions.setIsLoading(false));
  }
}

export function* onCompleteRegistration() {
  yield* takeLatest(
    USER_ACTION_TYPES.COMPLETE_REGISTRATION,
    updateCompleteRegistration
  );
}

export function* onGoogleSignInStart() {
  // yield* takeLatest(USER_ACTION_TYPES.GOOGLE_SIGN_IN_START, signInWithGoogle);
}

export function* onSignInSuccess() {
  yield* takeLatest(USER_ACTION_TYPES.SIGN_IN_SUCCESS, isUserAuthenticated);
}

export function* onCheckUserSession() {
  yield* takeLatest(USER_ACTION_TYPES.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onSignOutStart() {
  yield* takeLatest(USER_ACTION_TYPES.SIGN_OUT_START, signOut);
}

export function* userSagas() {
  yield* all([
    call(onCheckUserSession),
    call(onGoogleSignInStart),
    call(onCompleteRegistration),
    call(onSignInSuccess),
    call(onSignOutStart),
  ]);
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Issue, Ticket } from "utils/types/ticket";

export type SupportState = {
  readonly tickets: Ticket[] | null;
  readonly issues: Issue[] | null;
  searchValue: "";
  loading: boolean;
  start_date: string | null;
  end_date: string | null;
  sortOption: string;
  limit: number;
};

const INITIAL_STATE: SupportState = {
  tickets: null,
  issues: null,
  searchValue: "",
  loading: false,
  start_date: null,
  end_date: null,
  sortOption: "date_desc",
  limit: 10,
};
const supportSlice = createSlice({
  name: "support",
  initialState: INITIAL_STATE,
  reducers: {
    setAllTickets(state, action) {
      state.tickets = action.payload;
    },
    setAllIssues(state, action) {
      state.issues = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setIsLoading(state, action) {
      state.loading = action.payload;
    },
    setStartDate(state: SupportState, action: PayloadAction<string | null>) {
      state.start_date = action.payload;
    },
    setEndDate(state: SupportState, action: PayloadAction<string | null>) {
      state.end_date = action.payload;
    },
    setSortOption(state: SupportState, action: PayloadAction<string>) {
      state.sortOption = action.payload;
    },
    setLimit(state: SupportState, action: PayloadAction<number>) {
      state.limit = action.payload;
    },
  },
});

export const supportActions = supportSlice.actions;
export default supportSlice;

import { createBrowserRouter } from "react-router-dom";
import { Suspense } from "react";
import ErrorPage from "components/error/error.component";
import Authentication, { authRoutes } from "./auth";
import ProtectedRoutes, { protectedRoutes } from "./protected-routes";
import Preloader from "components/preloader";

const router = createBrowserRouter([
  {
    element: (
      <Suspense fallback={<Preloader />}>
        <Authentication />
      </Suspense>
    ),
    loader: () => <Preloader />,
    errorElement: <ErrorPage />,
    children: authRoutes,
  },
  {
    element: (
      <Suspense fallback={<Preloader />}>
        <ProtectedRoutes />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
    children: protectedRoutes,
  },
]);

export default router;

"use client";
function DashboardSvg({ className }: { className?: string }) {
  return (
    <svg
      width={25}
      height={25}
      className={className}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9258 9.15234V4.15234H20.4258V9.15234H13.9258ZM4.42578 12.1523V4.15234H10.9258V12.1523H4.42578ZM13.9258 20.1523V12.1523H20.4258V20.1523H13.9258ZM4.42578 20.1523V15.1523H10.9258V20.1523H4.42578ZM5.42578 11.1523H9.92578V5.15234H5.42578V11.1523ZM14.9258 19.1523H19.4258V13.1523H14.9258V19.1523ZM14.9258 8.15234H19.4258V5.15234H14.9258V8.15234ZM5.42578 19.1523H9.92578V16.1523H5.42578V19.1523Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default DashboardSvg;

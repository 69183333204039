import { addToSearches } from "store/data/data.actions";
import { classNames } from "utils/helper/helper";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

type Props = {
  title: string;
  uri: string;
};
function MenuSearchItem({ item: { title, uri } }: { item: Props }) {
  const dispatch = useDispatch();
  const router = useNavigate();
  const navigate = () => {
    dispatch(addToSearches({ title, uri }));
    router(uri);
  };
  return (
    <div
      className={classNames(
        "px-4 mt-2 group flex h-16 justify-between items-center rounded-lg shadow bg-gray-100 dark:bg-dark-base/50",
        "active:opacity-70 cursor-pointer transition-opacity hover:bg-secondary hover:text-secondary-foreground"
      )}
      role="button"
      onClick={navigate}>
      <div className="flex gap-3 items-center w-full max-w-full">
        <svg
          aria-hidden="true"
          fill="none"
          focusable="false"
          height={20}
          role="presentation"
          viewBox="0 0 24 24"
          width={20}
          className="text-default-500 dark:text-default-300 group-hover:text-primary-foreground">
          <path
            d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          />
          <path
            d="M22 22L20 20"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          />
        </svg>
        <div className="flex flex-col gap-0 justify-center max-w-[80%]">
          <p className="truncate text-default-500 group-hover:text-primary-foreground select-none">
            {title}
          </p>
        </div>
      </div>
      <svg
        aria-hidden="true"
        fill="none"
        focusable="false"
        height={14}
        role="presentation"
        viewBox="0 0 24 24"
        width={14}>
        <path
          d="M8.91003 19.9201L15.43 13.4001C16.2 12.6301 16.2 11.3701 15.43 10.6001L8.91003 4.08008"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth="1.5"
        />
      </svg>
    </div>
  );
}

export default MenuSearchItem;

import { createSelector } from "reselect";
import { RootState } from "../store";

const selectActivityReducer = (state: RootState) => state.activity;

export const selectAllActivity = createSelector(
  [selectActivityReducer],
  (data) => data.all
);

export const selectRecentActivities = createSelector(
  [selectActivityReducer],
  (data) => {
    if (!data.all) return null; // Handle case where activities might be null
    return data.all.slice(0, 12); // Return the first 12 activities
  }
);

import { Blog } from "store/data/data.types";
import { getEnvProps } from "utils/helper/server-helper";
import { getHeaders } from "utils/header";

export const getBlogBySlug = async (slug: string): Promise<Blog | null> => {
  const envProps = await getEnvProps();
  const headers = await getHeaders(undefined, true);

  let blog: Blog | null = null;

  try {
    const req = await fetch(
        `${envProps.base_url}/api/v1/admin/get-blog/${slug}`,
        {
          headers,
        }
      ),
      res = await req.json();

    if (res.success != null && res.success) {
      return res.data;
    } else {
      throw new Error("Error 404, Blog not found.");
    }
  } catch (error) {
    console.error("Error fetching blog:", error);
    // throw new Error("Error fetching blog");
  }

  return blog;
};

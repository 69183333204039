import { takeLatest, all, call, put } from "typed-redux-saga";

import { COLOR_ACTION_TYPES } from "./color.types";
import { AddColor, DeleteColor, UpdateColor } from "./color.actions";
import { colorActions } from "./color.slice";
import { apiRequest } from "lib/api/apiClient";
import { alertMessage } from "components/toolkit/initial-state.component";
import { logger } from "lib/logger";

export function* addColorFunc({ payload: formValues }: AddColor) {
  const { callback, ...form_data } = formValues;
  yield* put(colorActions.setLoading(true));
  try {
    const link = "/api/v1/admin/color";
    const data = yield* call(apiRequest, link, {
      method: "post",
      body: form_data,
    });
    if (!data) return;
    yield* put(colorActions.setAllColor(data));
    alertMessage("success", "color added successfully");
    callback();
  } catch (error) {
    logger.error(error as Error);
    alertMessage("error", "Failed to add color");
  } finally {
    yield* put(colorActions.setLoading(false));
  }
}

export function* updateColorFunc({ payload: formValues }: UpdateColor) {
  const { uri, ...form_data } = formValues;
  yield* put(colorActions.setLoading(true));
  try {
    const data = yield* call(
      apiRequest,
      uri,
      {
        method: "put",
        body: form_data,
      },
      true
    );
    if (!data) return;
    yield* put(colorActions.setAllColor(data));
    alertMessage("success", "Color updated successfully");
  } catch (error) {
    logger.error(error as Error);
    alertMessage("error", "Failed to updated color");
  } finally {
    yield* put(colorActions.setLoading(false));
  }
}
export function* deleteColorFunc({ payload: formValues }: DeleteColor) {
  yield* put(colorActions.setLoading(true));
  try {
    const link = formValues.uri;
    const data = yield* call(
      apiRequest,
      link,
      {
        method: "delete",
      },
      true
    );
    if (!data) return;
    yield* put(colorActions.setAllColor(data));
    alertMessage("success", "Color added successfully");
  } catch (error) {
    logger.error(error as Error);
    alertMessage("error", "Failed to add color");
  } finally {
    yield* put(colorActions.setLoading(false));
  }
}

export function* fetchColorData() {
  try {
    const data = yield* call(apiRequest, "/api/v1/admin/color");
    if (!data) return;
    yield* put(colorActions.setAllColor(data));
  } catch (error) {
    console.log(error as Error);
  }
}

export function* onAddColor() {
  yield* takeLatest(COLOR_ACTION_TYPES.ADD_COLOR, addColorFunc);
}

export function* onFetchColors() {
  yield* takeLatest(COLOR_ACTION_TYPES.FETCH_ALL_DATA, fetchColorData);
}
export function* onDeleteColor() {
  yield* takeLatest(COLOR_ACTION_TYPES.DELETE_COLOR, deleteColorFunc);
}
export function* onUpdateColor() {
  yield* takeLatest(COLOR_ACTION_TYPES.UPDATE_COLOR, updateColorFunc);
}
export function* colorSagas() {
  yield* all([
    call(onAddColor),
    call(onFetchColors),
    call(onDeleteColor),
    call(onUpdateColor),
  ]);
}

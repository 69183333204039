import {
  Action,
  ActionWithPayload,
  withMatcher,
  createAction,
} from "utils/reducer/reducer.utils";
import { STAFF_ACTION_TYPES } from "./staff.types";

type PermissionData = {
  name: string;
};
interface UpdatePermissionData extends PermissionData {
  uri: string;
}

export type AddPermission = ActionWithPayload<
  STAFF_ACTION_TYPES.ADD_PERMISSION,
  PermissionData
>;
export type UpdatePermission = ActionWithPayload<
  STAFF_ACTION_TYPES.UPDATE_PERMISSION,
  UpdatePermissionData
>;
export type FetchPermissions = Action<STAFF_ACTION_TYPES.FETCH_PERMISSION>;

export const addPermission = withMatcher(
  (formValues: PermissionData): AddPermission =>
    createAction(STAFF_ACTION_TYPES.ADD_PERMISSION, formValues)
);

export const updatePermission = withMatcher(
  (formValues: UpdatePermissionData): UpdatePermission =>
    createAction(STAFF_ACTION_TYPES.UPDATE_PERMISSION, formValues)
);

export const getPermissions = withMatcher(
  (): FetchPermissions => createAction(STAFF_ACTION_TYPES.FETCH_PERMISSION)
);

// ------------------------------------- Roles -------------------------------------
type RoleData = {
  name: string;
  permissions: (string | number)[];
  callback: () => void;
};
interface UpdateRoleData {
  name: string;
  permissions: (string | number)[];
  uri: string;
}

export type AddRole = ActionWithPayload<STAFF_ACTION_TYPES.ADD_ROLE, RoleData>;
export type UpdateRole = ActionWithPayload<
  STAFF_ACTION_TYPES.UPDATE_ROLE,
  UpdateRoleData
>;
export type FetchRoles = Action<STAFF_ACTION_TYPES.FETCH_ROLE>;
export type FetchEmails = Action<STAFF_ACTION_TYPES.FETCH_EMAILS>;

export const addRole = withMatcher(
  (formValues: RoleData): AddRole =>
    createAction(STAFF_ACTION_TYPES.ADD_ROLE, formValues)
);

export const updateRole = withMatcher(
  (formValues: UpdateRoleData): UpdateRole =>
    createAction(STAFF_ACTION_TYPES.UPDATE_ROLE, formValues)
);

export const getRoles = withMatcher(
  (): FetchRoles => createAction(STAFF_ACTION_TYPES.FETCH_ROLE)
);
export const getEmails = withMatcher(
  (): FetchEmails => createAction(STAFF_ACTION_TYPES.FETCH_EMAILS)
);

// ------------------------------------- Staff -------------------------------------
type StaffData = {
  name: string;
  email: string;
  phone_number: string;
  role_id: number;
  callback: () => void;
};
interface UpdateStaffData {
  name: string;
  email: string;
  phone_number: string;
  role_id: number;
  uri: string;
}

export type AddStaff = ActionWithPayload<
  STAFF_ACTION_TYPES.ADD_STAFF,
  StaffData
>;
export type UpdateStaff = ActionWithPayload<
  STAFF_ACTION_TYPES.UPDATE_STAFF,
  UpdateStaffData
>;
export type FetchStaffs = Action<STAFF_ACTION_TYPES.FETCH_STAFF>;

export const addStaff = withMatcher(
  (formValues: StaffData): AddStaff =>
    createAction(STAFF_ACTION_TYPES.ADD_STAFF, formValues)
);

export const updateStaff = withMatcher(
  (formValues: UpdateStaffData): UpdateStaff =>
    createAction(STAFF_ACTION_TYPES.UPDATE_STAFF, formValues)
);

export const getStaffs = withMatcher(
  (): FetchStaffs => createAction(STAFF_ACTION_TYPES.FETCH_STAFF)
);

import { createSlice } from "@reduxjs/toolkit";
import { Coupon } from "utils/types/coupon";

export type CouponState = {
  readonly all: Coupon[] | null;
  searchValue: "";
  loading: boolean;
};

const INITIAL_STATE: CouponState = {
  all: null,
  searchValue: "",
  loading: false,
};
const couponSlice = createSlice({
  name: "coupons",
  initialState: INITIAL_STATE,
  reducers: {
    setAllCoupon(state, action) {
      state.all = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const couponActions = couponSlice.actions;
export default couponSlice;

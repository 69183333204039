"use client";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  SharedSelection,
} from "@nextui-org/react";
import CheckboxSvg from "assets/svgs/checkbox.svg";
import { alertMessage } from "components/toolkit/initial-state.component";
import { apiRequest } from "lib/api/apiClient";
import { logger } from "lib/logger";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectStaff } from "store/staff/staff.selector";
import { TicketResponse } from "utils/types/ticket";

function PassIssue({
  ticket,
  setTicket,
}: {
  ticket: TicketResponse;
  setTicket: React.Dispatch<React.SetStateAction<TicketResponse>>;
}) {
  const [selectedKeys, setSelectedKeys] = useState<SharedSelection | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);
  const staffs = useSelector(selectStaff) || [];

  const handleSelect = async (keys: SharedSelection) => {
    const selected = keys.currentKey;
    setLoading(true);
    try {
      const res = await apiRequest(`/api/v1/admin/ticket/${ticket.id}`, {
        method: "put",
        body: {
          assigned_to: selected,
        },
      });
      if (res) {
        alertMessage("success", "Ticket assigned successfully");
        setSelectedKeys(keys);
        setTicket(res);
      }
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Dropdown closeOnSelect={false}>
      <DropdownTrigger>
        <Button
          isLoading={loading}
          variant="bordered"
          color="primary"
          className="dark:border-primary-100 dark:text-primary-100">
          {!loading && <CheckboxSvg />} Pass Issue
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        aria-label="Dynamic Actions"
        items={staffs || undefined}
        variant="flat"
        closeOnSelect={false}
        disallowEmptySelection
        selectionMode={"single"}
        selectedKeys={selectedKeys}
        onSelectionChange={handleSelect}>
        {(item) => (
          <DropdownItem
            key={item.id}
            color={"default"}>
            {item.name}
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
}
export default PassIssue;

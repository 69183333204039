import { getHeaders } from "utils/header";
import { getEnvProps } from "utils/helper/server-helper";
import { Role } from "utils/types/staff";

export const getRoleByID = async (id: string): Promise<Role | null> => {
  const envProps = await getEnvProps();
  const headers = await getHeaders(undefined, true);

  let role: Role | null = null;

  try {
    const req = await fetch(`${envProps.base_url}/api/v1/admin/role/${id}`, {
        headers,
      }),
      res = await req.json();

    if (res.success != null && res.success) {
      return res.data;
    } else {
      throw new Error("Error 404, role not found.");
    }
  } catch (error) {
    console.error("Error fetching role:", error);
    // throw new Error("Error fetching role");
  }

  return role;
};

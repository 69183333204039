function LogisticSvg({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width={26}
      height={26}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.63379 14.6982H2.50879V15.7399H5.63379V14.6982Z"
        fill="currentColor"
      />
      <path
        d="M5.63379 10.5312H2.50879V11.5729H5.63379V10.5312Z"
        fill="currentColor"
      />
      <path
        d="M5.63379 6.36523H2.50879V7.4069H5.63379V6.36523Z"
        fill="currentColor"
      />
      <path
        d="M23.3424 13.1357L21.2591 8.96908H19.1758V6.94564C19.1758 5.7998 18.2383 4.8623 17.0924 4.8623H8.75911C7.61328 4.8623 6.67578 5.7998 6.67578 6.94564V17.3024C6.67578 18.4482 7.61328 19.3857 8.75911 19.3857H9.80078C9.80078 20.5363 10.7336 21.4691 11.8841 21.4691C13.0346 21.4691 13.9674 20.5363 13.9674 19.3857H17.0924H17.6133C17.6133 20.5363 18.5461 21.4691 19.6966 21.4691C20.8471 21.4691 21.7799 20.5363 21.7799 19.3857H23.3424V13.1357ZM20.7383 19.3857C20.7383 19.9602 20.2711 20.4274 19.6966 20.4274C19.1221 20.4274 18.6549 19.9602 18.6549 19.3857C18.6549 18.8113 19.1221 18.3441 19.6966 18.3441C20.2711 18.3441 20.7383 18.8113 20.7383 19.3857ZM12.9258 19.3857C12.9258 19.9602 12.4586 20.4274 11.8841 20.4274C11.3096 20.4274 10.8424 19.9602 10.8424 19.3857C10.8424 18.8113 11.3096 18.3441 11.8841 18.3441C12.4586 18.3441 12.9258 18.8113 12.9258 19.3857ZM11.8841 17.3024C11.1148 17.3024 10.4508 17.7238 10.0898 18.3441H8.75911C8.18464 18.3441 7.71745 17.8769 7.71745 17.3024V6.94564C7.71745 6.37116 8.18464 5.90397 8.75911 5.90397H17.0924C17.6669 5.90397 18.1341 6.37116 18.1341 6.94564V10.0107V17.3024C18.1341 17.8769 17.6669 18.3441 17.0924 18.3441H13.6784C13.3174 17.7238 12.6534 17.3024 11.8841 17.3024ZM22.3008 18.3441H21.4909C21.1299 17.7238 20.4659 17.3024 19.6966 17.3024C19.5128 17.3024 19.3378 17.3337 19.168 17.3785C19.169 17.3524 19.1758 17.3285 19.1758 17.3024V10.0107H20.6154L22.3008 13.3816V18.3441Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default LogisticSvg;

function VendorSvg({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width={26}
      height={26}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_218_9415)">
        <path
          d="M5.89486 11.4648C6.86257 11.4648 7.71986 10.9826 8.23861 10.2456C8.75736 10.9826 9.61465 11.4648 10.5824 11.4648C11.5501 11.4648 12.4074 10.9826 12.9261 10.2456C13.4449 10.9826 14.3021 11.4648 15.2699 11.4648C16.2376 11.4648 17.0949 10.9826 17.6136 10.2456C18.1324 10.9826 18.9896 11.4648 19.9574 11.4648C21.5365 11.4648 22.8219 10.1794 22.8219 8.60026V5.54036C22.8219 5.45391 22.8115 5.37005 22.8006 5.2862V5.28464C22.812 5.19766 22.8219 5.10964 22.8219 5.01953C22.8219 3.72734 21.7704 2.67578 20.4782 2.67578H5.89486C5.736 2.67578 5.57715 2.69193 5.44642 2.71953C4.04642 2.94349 3.03027 4.12995 3.03027 5.54036V8.60026C3.03027 10.1794 4.31517 11.4648 5.89486 11.4648ZM4.07194 5.54036C4.07194 4.65911 4.73392 3.88828 5.63392 3.74401C5.71882 3.7263 5.80684 3.71745 5.89486 3.71745H20.4782C21.1964 3.71745 21.7803 4.3013 21.7803 5.01953C21.7803 5.07682 21.7699 5.13203 21.7594 5.20339L21.7495 5.27057L21.7683 5.42786C21.7735 5.46432 21.7803 5.50182 21.7803 5.54036V8.60026C21.7803 9.60547 20.9626 10.4232 19.9574 10.4232C18.9521 10.4232 18.1344 9.60547 18.1344 8.60026V6.84245H17.0928V8.60026C17.0928 9.60547 16.2751 10.4232 15.2699 10.4232C14.2646 10.4232 13.4469 9.60547 13.4469 8.60026V6.84245H12.4053V8.60026C12.4053 9.60547 11.5876 10.4232 10.5824 10.4232C9.57715 10.4232 8.75944 9.60547 8.75944 8.60026V6.84245H7.71777V8.60026C7.71777 9.60547 6.90007 10.4232 5.89486 10.4232C4.88965 10.4232 4.07194 9.60547 4.07194 8.60026V5.54036Z"
          fill="currentColor"
        />
        <path
          d="M21.7796 18.3005V17.2588V14.1338H20.738V17.2588H11.363V14.1338H10.3213V17.2588V18.3005V21.4255H5.11296V14.1338H4.07129V22.4671H21.7796V18.3005ZM11.363 21.4255V18.3005H20.738V21.4255H11.363Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_218_9415">
          <rect
            width={25}
            height={25}
            fill="white"
            transform="translate(0.425781 0.0712891)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default VendorSvg;

import FormInput from "components/form/form-input.component";

import { classNames } from "utils/helper/helper";
import React, { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { supportActions } from "store/tickets/ticket.slice";
import {
  selectTicketEnd,
  selectTicketStart,
} from "store/tickets/ticket.selector";

const DateRangeSelector: React.FC = () => {
  const startDate = useSelector(selectTicketStart);
  const endDate = useSelector(selectTicketEnd);
  const dispatch = useDispatch();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "startDate") dispatch(supportActions.setStartDate(value));
    if (name === "endDate") dispatch(supportActions.setEndDate(value));
  };
  const clearFilters = () => {
    dispatch(supportActions.setStartDate(null));
    dispatch(supportActions.setEndDate(null));
  };

  return (
    <div className="w-full">
      <div className="flex items-center flex-col gap-4">
        <FormInput
          label="Start Date"
          type="date"
          id="start-date"
          name="startDate"
          onChange={handleChange}
          value={startDate || ""}
        />
        <FormInput
          label="End Date"
          type="date"
          id="end-date"
          name="endDate"
          onChange={handleChange}
          value={endDate || ""}
        />
        <div className="flex items-end">
          <button
            onClick={clearFilters}
            className={classNames(
              "btn btn-sm text-sm bg-white border-[#E0E2E7] dark:border-dark-border",
              "text-[#667085] dark:bg-primary-950 dark:text-white h-10"
            )}>
            Clear Range Filter
          </button>
        </div>
      </div>
    </div>
  );
};

export default DateRangeSelector;

import AdsSvg from "./ads.svg";
import AnalysisSvg from "./analysis.svg";
import BlogSvg from "./blog.svg";
import DashboardSvg from "./dashboard.svg";
import LogisticSvg from "./logistics.svg";
import OrderSvg from "./orders.svg";
import ProductSvg from "./product.svg";
import ReportSvg from "./reports.svg";
import SettingSvg from "./settings.svg";
import StaffSvg from "./staff.svg";
import SupportSvg from "./support.svg";
import UsersSvg from "./users.svg";
import VendorSvg from "./vendors.svg";

function SvgData({
  name,
  className = "",
}: {
  name: string;
  className: string;
}) {
  switch (name) {
    case "dashboard":
      return <DashboardSvg className={className} />;
    case "product":
      return <ProductSvg className={className} />;
    case "orders":
      return <OrderSvg className={className} />;
    case "users":
      return <UsersSvg className={className} />;
    case "ads":
      return <AdsSvg className={className} />;
    case "analysis":
      return <AnalysisSvg className={className} />;
    case "vendors":
      return <VendorSvg className={className} />;
    case "reports":
      return <ReportSvg className={className} />;
    case "logistics":
      return <LogisticSvg className={className} />;
    case "support":
      return <SupportSvg className={className} />;
    case "settings":
      return <SettingSvg className={className} />;
    case "staff":
      return <StaffSvg className={className} />;
    case "blog":
      return <BlogSvg className={className} />;

    default:
      return <DashboardSvg className={className} />;
  }
}

export default SvgData;

import DashboardLayout from "components/layouts/main-layout";
import TicketResponses from "components/ticket";
import TicketResponsePageHeader from "components/ticket/page-header";
import { logger } from "lib/logger";
import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { TicketResponse } from "utils/types/ticket";

function ViewResponses() {
  const ticket = useLoaderData() as TicketResponse;
  const [data, setData] = useState(ticket);
  logger.log(ticket);
  return (
    <DashboardLayout name="support">
      <main className="main-content relative h-screen overflow-hidden flex flex-col">
        <TicketResponsePageHeader
          ticket={data}
          setTicket={setData}
        />
        <TicketResponses
          ticket={data}
          setTicket={setData}
        />
      </main>
    </DashboardLayout>
  );
}

export default ViewResponses;

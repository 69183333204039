export enum STAFF_ACTION_TYPES {
  SET_PERMISSIONS = "staff/SET_PERMISSIONS",
  ADD_PERMISSION = "staff/ADD_PERMISSION",
  FETCH_PERMISSION = "staff/FETCH_PERMISSION",
  UPDATE_PERMISSION = "staff/UPDATE_PERMISSION",

  SET_ROLES = "staff/SET_ROLES",
  ADD_ROLE = "staff/ADD_ROLE",
  FETCH_ROLE = "staff/FETCH_ROLE",
  UPDATE_ROLE = "staff/UPDATE_ROLE",

  SET_STAFFS = "staff/SET_STAFFS",
  ADD_STAFF = "staff/ADD_STAFF",
  FETCH_STAFF = "staff/FETCH_STAFF",
  UPDATE_STAFF = "staff/UPDATE_STAFF",

  FETCH_EMAILS = "staff/FETCH_EMAILS",
}

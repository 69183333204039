import { Action, withMatcher, createAction } from "utils/reducer/reducer.utils";
import { Blog } from "utils/types/blog";
import { blogActions } from "./blog.slice";
import { BLOG_ACTION_TYPES } from "./blog.types";

export const setAllBlog = (value: Blog[]) => blogActions.setAllBlog(value);

export const setBlogSearchValue = (value: string) =>
  blogActions.setSearchValue(value);

export type FetchAllData = Action<BLOG_ACTION_TYPES.FETCH_ALL_DATA>;

export const fetchAllBlog = withMatcher(
  (): FetchAllData => createAction(BLOG_ACTION_TYPES.FETCH_ALL_DATA)
);

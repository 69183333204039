import { createSelector } from "reselect";
import { RootState } from "../store";
import { sortTickets } from "utils/helper/helper";
import { Ticket } from "utils/types/ticket";

const selectSupportReducer = (state: RootState) => state.support;

export const selectAllTickets = createSelector(
  [selectSupportReducer],
  (data) => data.tickets
);
const filterTickets = (
  tickets: Ticket[] | null,
  searchValue: string,
  startDate: string | null,
  endDate: string | null
): Ticket[] | null => {
  if (!tickets) return null;

  return tickets.filter((ticked) => {
    const isMatch =
      ticked.code.toLowerCase().includes(searchValue.toLowerCase()) ||
      ticked.subject.toLowerCase().includes(searchValue.toLowerCase());
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;
    const addedDate = new Date(ticked.created_at);

    return (
      isMatch && (!start || addedDate >= start) && (!end || addedDate <= end)
    );
  });
};
export const selectFilteredTickets = createSelector(
  [selectSupportReducer],
  (data) => {
    const tickets = data.tickets,
      searchValue = data.searchValue;
    const filtered = tickets?.filter((ticket) => {
      const codeMatches = ticket.code
        .toLowerCase()
        .includes(searchValue.toLowerCase());
      return codeMatches;
    });
    return filtered == null ? null : filtered;
  }
);
export const selectTickets = createSelector([selectSupportReducer], (data) => {
  if (!data.tickets) return null;

  const filteredTickets =
    filterTickets(
      data.tickets,
      data.searchValue,
      data.start_date,
      data.end_date
    ) || [];

  return sortTickets(filteredTickets, data.sortOption);
});

export const selectTicketLimit = createSelector(
  [selectSupportReducer],
  (data) => data.limit
);

export const selectIssues = createSelector(
  [selectSupportReducer],
  (data) => data.issues
);

export const selectTicketStart = createSelector(
  [selectSupportReducer],
  (data) => data.start_date
);

export const selectTicketEnd = createSelector(
  [selectSupportReducer],
  (data) => data.end_date
);

export const selectTicketLoading = createSelector(
  [selectSupportReducer],
  (data) => data.loading
);

import { takeLatest, all, call, put } from "typed-redux-saga";

import { CATEGORY_ACTION_TYPES } from "./category.types";
import { categoryActions } from "./category.slice";
import { apiRequest } from "lib/api/apiClient";

export function* fetchData() {
  yield* put(categoryActions.setIsLoading(true));
  try {
    const link = "/api/v1/admin/category";
    const data = yield* call(apiRequest, link);
    if (!data) return;
    yield* put(categoryActions.setAllCategory(data));
  } catch (error) {
    console.log(error as Error);
  } finally {
    yield* put(categoryActions.setIsLoading(false));
  }
}
export function* onFetchData() {
  yield* takeLatest(CATEGORY_ACTION_TYPES.FETCH_ALL_DATA, fetchData);
}

export function* categorySagas() {
  yield* all([call(onFetchData)]);
}

import { getBlogBySlug } from "actions/blog";
import { LoaderFunctionArgs } from "react-router-dom";

export const blogLoader = async ({ params }: LoaderFunctionArgs) => {
  const { slug } = params;
  if (slug) {
    const blog = await getBlogBySlug(slug);
    if (blog == null) {
      throw new Response("Error 404, blog not found.", { status: 404 });
    }
    return blog;
  }
  return null;
};

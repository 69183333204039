import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
} from "@nextui-org/react";
import { useEffect, useRef } from "react";
import { extractFirstName, formatDateTime } from "utils/helper/helper";
import SendMessage from "./send-message";
import ChatMessage from "./chatbox";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "store/user/user.selector";
import { TicketResponse } from "utils/types/ticket";

function TicketResponses({
  ticket,
  setTicket,
}: {
  ticket: TicketResponse;
  setTicket: React.Dispatch<React.SetStateAction<TicketResponse>>;
}) {
  const user = useSelector(selectCurrentUser);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  // Scroll to the bottom when new messages are added
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [ticket.responses]); // Trigger scrolling when responses change

  const firstMessage = (
    <div className={`flex justify-start mb-4`}>
      <div className="space-y-4">
        <div
          className={`bg-[#f5f7fb] border border-[#a1a4b6] text-black rounded-lg rounded-bl-none p-4 max-w-xs shadow`}>
          <p
            className="text-sm mb-2"
            dangerouslySetInnerHTML={{ __html: ticket.description }}
          />
        </div>
        <div className="text-xs text-right text-gray-500">
          {formatDateTime(ticket.created_at)}
        </div>
      </div>
    </div>
  );

  return (
    <Card
      className="py-4 lg:px-4 h-4/5"
      radius="md"
      shadow="sm">
      <CardHeader>
        <h4 className="font-medium text-2xl">
          {ticket.subject} (#{ticket.code})
        </h4>
      </CardHeader>
      <Divider />
      <CardBody className="overflow-y-auto h-3/4 gap-4">
        {firstMessage}
        {ticket.responses.map((response) => (
          <ChatMessage
            key={response.id}
            message={response.response}
            senderName={extractFirstName(response.user.name)}
            isSender={response.user.id === user?.id}
            timestamp={formatDateTime(response.created_at)}
            isAdmin={
              response.user.type === "admin" || response.user.type === "staff"
            }
          />
        ))}
        {/* Ref element to scroll into view */}
        <div ref={messagesEndRef} />
      </CardBody>
      <CardFooter>
        <SendMessage
          ticket={ticket}
          setTicket={setTicket}
        />
      </CardFooter>
    </Card>
  );
}

export default TicketResponses;

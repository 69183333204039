"use client";

import { classNames } from "utils/helper/helper";
import { InputHTMLAttributes, useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import ForgotPassword from "../auth/forgot-password";

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  errorMessage?: string | null;
  hasForgotPassword?: boolean;
}

const extractWidthClass = (className: string | undefined) => {
  if (!className) return null;
  const widthClass = className
    .split(" ")
    .find((cls) => cls.startsWith("w-") || cls.startsWith("col-"));
  return widthClass || null;
};

const FormInput: React.FC<InputFieldProps> = ({
  label,
  errorMessage,
  className,
  hasForgotPassword,
  ...props
}) => {
  const [hidden, setHidden] = useState(true);
  const { type, ...otherProps } = props;
  const inputType = type === "password" ? (hidden ? "password" : "text") : type;
  const widthClass = extractWidthClass(className) || "w-full";

  const togglePassword = () => setHidden((state) => !state);

  return (
    <label className={classNames("form-control", widthClass)}>
      {label != null && (
        <div className="label">
          <span className="label-text">{label}</span>
          {hasForgotPassword != null && hasForgotPassword && (
            <span className="label-text-alt">
              <ForgotPassword />
            </span>
          )}
        </div>
      )}
      <div className="relative">
        <input
          className={classNames(
            "input input-bordered text-black placeholder:text-sm rounded-lg dark:text-gray-200",
            widthClass,
            errorMessage != null && "border-red-600",
            className
          )}
          type={inputType}
          {...otherProps}
        />
        {type != null && type === "password" && (
          <span
            role="button"
            onClick={togglePassword}
            className="w-4 h-4 opacity-70 absolute top-4 right-4">
            {hidden ? <BsEye /> : <BsEyeSlash />}
          </span>
        )}
      </div>
      {errorMessage != null && (
        <div className="label">
          <span className="label-text-alt text-red-600">{errorMessage}</span>
        </div>
      )}
    </label>
  );
};

export default FormInput;

import Preloader from "components/preloader";
import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import router from "routes";

function App() {
  return (
    <>
      <Suspense fallback={<Preloader />}>
        <RouterProvider router={router} />
      </Suspense>

      <ToastContainer />
    </>
  );
}

export default App;

function AdsSvg({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width={26}
      height={26}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.7792 20.8298L15.3771 14.4277L14.6406 15.1642L21.0427 21.5663H16.05V22.6079H22.8208V15.8371H21.7792V20.8298Z"
        fill="currentColor"
      />
      <path
        d="M16.0505 3.85905H21.0432L14.6406 10.2617L15.3771 10.9981L21.7797 4.59551V9.58822H22.8214V2.81738H16.0505V3.85905Z"
        fill="currentColor"
      />
      <path
        d="M4.07096 4.59453L10.4741 10.9977L11.2105 10.2612L4.80742 3.85807H9.80013V2.81641H3.0293V9.58724H4.07096V4.59453Z"
        fill="currentColor"
      />
      <path
        d="M9.80111 21.5667H4.84954L11.211 15.2052L10.474 14.4688L4.07194 20.8714V15.8385H3.03027V22.6083H9.80111V21.5667Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default AdsSvg;

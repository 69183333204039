"use client";
import React, { useState } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  useDisclosure,
  Dropdown,
  DropdownTrigger,
  Button,
  DropdownItem,
  DropdownMenu,
} from "@nextui-org/react";
import { useSelector } from "react-redux";
import { usePagination } from "utils/hooks/usePagination";
import TablePagination from "components/main/table-paginantion.component";
import { Seller } from "utils/types/user";
import { selectCustomers } from "store/users/users.selector";
import Badge, { BadgeType } from "../display/badge.component";
import { IoEllipsisVerticalOutline } from "react-icons/io5";

export default function CustomersList() {
  const { isOpen, onOpen, onOpenChange } = useDisclosure(),
    [staff, setStaff] = useState<Seller | null>(null);
  const customers = useSelector(selectCustomers);
  const { page, totalPages, setPage, paginatedItems } = usePagination(
    customers,
    12
  );
  const viewSeller = (item: Seller) => {
    setStaff(item);
    onOpen();
  };

  return (
    <>
      <div className="flex flex-col gap-3 mt-3">
        <Table
          color={"default"}
          classNames={{
            wrapper: "table-scrollbar",
          }}
          aria-label="List of Customers"
          bottomContent={
            <TablePagination
              page={page}
              totalPages={totalPages}
              onPageChange={(page) => setPage(page)}
            />
          }
          onRowAction={(key) => null}>
          <TableHeader>
            <TableColumn>#</TableColumn>
            <TableColumn>Name</TableColumn>
            <TableColumn>Phone Number</TableColumn>
            <TableColumn>Email</TableColumn>
            <TableColumn>Status</TableColumn>
            <TableColumn>Options</TableColumn>
          </TableHeader>
          <TableBody emptyContent={"No User to display."}>
            {paginatedItems == null
              ? []
              : paginatedItems.map((user, index) => (
                  <TableRow key={user.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>
                      <a href={`tel:${user.phone_number}`}>
                        {user.phone_number}
                      </a>
                    </TableCell>
                    <TableCell>
                      <a href={`mailto:${user.email}`}>{user.email}</a>{" "}
                    </TableCell>
                    <TableCell>
                      <Badge
                        type={
                          user.email_verified
                            ? BadgeType.success
                            : BadgeType.pending
                        }>
                        {user.email_verified ? "Verified" : "Not verified"}
                      </Badge>
                    </TableCell>
                    <TableCell>
                      <Dropdown>
                        <DropdownTrigger>
                          <Button
                            variant="bordered"
                            isIconOnly>
                            <IoEllipsisVerticalOutline />
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu
                          variant="flat"
                          aria-label="Dropdown menu with shortcut">
                          <DropdownItem
                            key="new"
                            shortcut="⌘N">
                            New file
                          </DropdownItem>
                          <DropdownItem
                            key="copy"
                            shortcut="⌘C">
                            Copy link
                          </DropdownItem>
                          <DropdownItem
                            key="edit"
                            shortcut="⌘⇧E">
                            Edit file
                          </DropdownItem>
                          <DropdownItem
                            key="delete"
                            shortcut="⌘⇧D"
                            className="text-danger"
                            color="danger">
                            Delete file
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </div>
      {/* <ViewRole {...{ isOpen, onOpenChange, role }} /> */}
    </>
  );
}

function AnalysisSvg({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width={26}
      height={26}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.63607 23.4111H6.7194C7.86523 23.4111 8.80273 22.4736 8.80273 21.3278V19.2445C8.80273 18.0986 7.86523 17.1611 6.7194 17.1611H4.63607C3.49023 17.1611 2.55273 18.0986 2.55273 19.2445V21.3278C2.55273 22.4736 3.49023 23.4111 4.63607 23.4111ZM3.5944 19.2445C3.5944 18.67 4.06159 18.2028 4.63607 18.2028H6.7194C7.29388 18.2028 7.76107 18.67 7.76107 19.2445V21.3278C7.76107 21.9023 7.29388 22.3695 6.7194 22.3695H4.63607C4.06159 22.3695 3.5944 21.9023 3.5944 21.3278V19.2445Z"
        fill="currentColor"
      />
      <path
        d="M11.9271 23.4105H14.0104C15.1562 23.4105 16.0938 22.473 16.0938 21.3271V14.0355C16.0938 12.8896 15.1562 11.9521 14.0104 11.9521H11.9271C10.7812 11.9521 9.84375 12.8896 9.84375 14.0355V21.3271C9.84375 22.473 10.7812 23.4105 11.9271 23.4105ZM10.8854 14.0355C10.8854 13.461 11.3526 12.9938 11.9271 12.9938H14.0104C14.5849 12.9938 15.0521 13.461 15.0521 14.0355V21.3271C15.0521 21.9016 14.5849 22.3688 14.0104 22.3688H11.9271C11.3526 22.3688 10.8854 21.9016 10.8854 21.3271V14.0355Z"
        fill="currentColor"
      />
      <path
        d="M19.2191 23.4105H21.3024C22.4482 23.4105 23.3857 22.473 23.3857 21.3271V4.66048C23.3857 3.51465 22.4482 2.57715 21.3024 2.57715H19.2191C18.0732 2.57715 17.1357 3.51465 17.1357 4.66048V21.3271C17.1357 22.473 18.0732 23.4105 19.2191 23.4105ZM18.1774 4.66048C18.1774 4.086 18.6446 3.61882 19.2191 3.61882H21.3024C21.8769 3.61882 22.3441 4.086 22.3441 4.66048V21.3271C22.3441 21.9016 21.8769 22.3688 21.3024 22.3688H19.2191C18.6446 22.3688 18.1774 21.9016 18.1774 21.3271V4.66048Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default AnalysisSvg;

function OrderSvg({ className }: { className?: string }) {
  return (
    <svg
      width={25}
      height={25}
      className={className}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_218_9403)">
        <path
          d="M20.0725 16.4147L23.024 7.56055H7.05163L6.00996 4.43555H3.03027V5.47721H5.25892L8.72194 15.8668L6.39538 21.1022H20.7386V20.0605H7.9985L9.61881 16.4147H20.0725ZM21.5782 8.60221L19.3214 15.373H9.65579L7.39902 8.60221H21.5782Z"
          fill="currentColor"
        />
        <path
          d="M10.8424 23.7054C11.4177 23.7054 11.8841 23.239 11.8841 22.6637C11.8841 22.0884 11.4177 21.6221 10.8424 21.6221C10.2672 21.6221 9.80078 22.0884 9.80078 22.6637C9.80078 23.239 10.2672 23.7054 10.8424 23.7054Z"
          fill="currentColor"
        />
        <path
          d="M17.0924 23.7054C17.6677 23.7054 18.1341 23.239 18.1341 22.6637C18.1341 22.0884 17.6677 21.6221 17.0924 21.6221C16.5172 21.6221 16.0508 22.0884 16.0508 22.6637C16.0508 23.239 16.5172 23.7054 17.0924 23.7054Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_218_9403">
          <rect
            width={25}
            height={25}
            fill="white"
            transform="translate(0.425781 0.789062)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default OrderSvg;

import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  User,
} from "@nextui-org/react";
import { useSelector } from "react-redux";
import { FaRegEye } from "react-icons/fa6";
import { usePagination } from "utils/hooks/usePagination";

import { classNames } from "utils/helper/helper";
import { Link } from "react-router-dom";
import {
  selectTickets,
  selectTicketLimit,
} from "store/tickets/ticket.selector";
import TicketStatus from "./status.component";
import TablePagination from "components/main/table-paginantion.component";

export default function TicketList() {
  const tickets = useSelector(selectTickets);
  const limit = useSelector(selectTicketLimit);
  const { page, totalPages, setPage, paginatedItems, paginationSummary } =
    usePagination(tickets, limit);

  const paginantion = (
    <div
      className={classNames(
        "flex items-center md:justify-between lg:justify-between",
        "flex-col md:flex-row lg:flex-row gap-4 w-full"
      )}>
      {totalPages > 0 && (
        <div className="text-sm font-normal">{paginationSummary}</div>
      )}
      <TablePagination
        page={page}
        totalPages={totalPages}
        onPageChange={(page) => setPage(page)}
      />
    </div>
  );
  return (
    <>
      <div className="flex flex-col gap-3 mt-3">
        <Table
          color={"default"}
          classNames={{
            wrapper: "table-scrollbar",
          }}
          bottomContent={paginantion}
          aria-label="Ticket Table"
          onRowAction={(key) => null}>
          <TableHeader>
            <TableColumn>ID</TableColumn>
            <TableColumn>Ticket Title</TableColumn>
            <TableColumn>User</TableColumn>
            <TableColumn>Purpose</TableColumn>
            <TableColumn>Status</TableColumn>
            <TableColumn>Assigned To</TableColumn>
            <TableColumn>Action</TableColumn>
          </TableHeader>
          <TableBody emptyContent={"No ticket to display."}>
            {paginatedItems == null
              ? []
              : paginatedItems.map((ticket) => (
                  <TableRow key={ticket.id}>
                    <TableCell>{ticket.code}</TableCell>
                    <TableCell>{ticket.subject}</TableCell>
                    <TableCell>
                      <User
                        avatarProps={{
                          isBordered: true,
                          src: ticket.user.avatar,
                        }}
                        className="transition-transform"
                        description={ticket.user.type}
                        name={ticket.user.name}
                      />
                    </TableCell>
                    <TableCell>{ticket.issue.name}</TableCell>
                    <TableCell>
                      <TicketStatus ticket={ticket} />
                    </TableCell>

                    <TableCell>
                      {ticket.assigned ? ticket.assigned.name : "Anyone"}
                    </TableCell>
                    <TableCell>
                      <div className="relative flex items-center gap-2">
                        <Tooltip content="View Responses">
                          <Link
                            to={`/support/tickets/${ticket.code}`}
                            className="text-lg text-default-400 cursor-pointer active:opacity-50">
                            <FaRegEye />
                          </Link>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
}

import {
  ActionWithPayload,
  withMatcher,
  createAction,
  Action,
} from "utils/reducer/reducer.utils";
import { ATTRIBUTE_ACTION_TYPES } from "./attribute.types";

type AttributeData = {
  name: string;
  callback: () => void;
};
type DeleteAttributeData = {
  uri: string;
};

type AttributeValue = {
  value: string;
  attribute_id?: number;
  callback: () => void;
};
type UpdateAttributeValueData = {
  value: string;
  uri: string;
};
type UpdateAttributeData = {
  name: string;
  uri: string;
};

export type UpdateAttribute = ActionWithPayload<
  ATTRIBUTE_ACTION_TYPES.UPDATE_ATTRIBUTE,
  UpdateAttributeData
>;
export type UpdateAttributeValue = ActionWithPayload<
  ATTRIBUTE_ACTION_TYPES.UPDATE_ATTRIBUTE_VALUE,
  UpdateAttributeValueData
>;
export type AddAttribute = ActionWithPayload<
  ATTRIBUTE_ACTION_TYPES.ADD_ATTRIBUTE,
  AttributeData
>;
export type AddAttributeValue = ActionWithPayload<
  ATTRIBUTE_ACTION_TYPES.ADD_ATTRIBUTE_VALUE,
  AttributeValue
>;

export type DeleteAttributeValue = ActionWithPayload<
  ATTRIBUTE_ACTION_TYPES.DELETE_ATTRIBUTE_VALUE,
  { id: number }
>;
export type DeleteAttribute = ActionWithPayload<
  ATTRIBUTE_ACTION_TYPES.DELETE_ATTRIBUTE,
  DeleteAttributeData
>;

export const addAttribute = withMatcher(
  (formValues: AttributeData): AddAttribute =>
    createAction(ATTRIBUTE_ACTION_TYPES.ADD_ATTRIBUTE, formValues)
);

export const updateAttribute = withMatcher(
  (formValues: UpdateAttributeData): UpdateAttribute =>
    createAction(ATTRIBUTE_ACTION_TYPES.UPDATE_ATTRIBUTE, formValues)
);
export const deleteAttribute = withMatcher(
  (data: DeleteAttributeData): DeleteAttribute =>
    createAction(ATTRIBUTE_ACTION_TYPES.DELETE_ATTRIBUTE, data)
);
export const addAttributeValue = withMatcher(
  (formValues: AttributeValue): AddAttributeValue =>
    createAction(ATTRIBUTE_ACTION_TYPES.ADD_ATTRIBUTE_VALUE, formValues)
);
export const updateAttributeValue = withMatcher(
  (formValues: UpdateAttributeValueData): UpdateAttributeValue =>
    createAction(ATTRIBUTE_ACTION_TYPES.UPDATE_ATTRIBUTE_VALUE, formValues)
);

export const deleteAttributeValue = withMatcher(
  (formValues: { id: number }): DeleteAttributeValue =>
    createAction(ATTRIBUTE_ACTION_TYPES.DELETE_ATTRIBUTE_VALUE, formValues)
);

export type FetchAttributes = Action<ATTRIBUTE_ACTION_TYPES.FETCH_ALL_DATA>;

export const getAttributes = withMatcher(
  (): FetchAttributes => createAction(ATTRIBUTE_ACTION_TYPES.FETCH_ALL_DATA)
);

"use client";
import { toggleIsSideBarSm } from "store/data/data.actions";
import { Button } from "@nextui-org/react";
import { CiMenuFries } from "react-icons/ci";
import { useDispatch } from "react-redux";

function SideBarSmToggle() {
  const dispatch = useDispatch(),
    toggle = () => dispatch(toggleIsSideBarSm());
  return (
    <Button
      isIconOnly
      variant="light"
      onPress={toggle}
      className="hidden lg:flex">
      <CiMenuFries className="dark:text-white w-5 h-5 " />
    </Button>
  );
}

export default SideBarSmToggle;

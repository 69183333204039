import { Attributes } from "utils/types/attribute";
import { createSlice } from "@reduxjs/toolkit";

export type AttributeState = {
  readonly all: Attributes | null;
  searchValue: "";
  loading: boolean;
};

const INITIAL_STATE: AttributeState = {
  all: null,
  searchValue: "",
  loading: false,
};
const attributeSlice = createSlice({
  name: "attribute",
  initialState: INITIAL_STATE,
  reducers: {
    setAllAttribute(state, action) {
      state.all = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const attributeActions = attributeSlice.actions;
export default attributeSlice;

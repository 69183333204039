import { takeLatest, all, call, put } from "typed-redux-saga";

import { SUPPORT_ACTION_TYPES } from "./ticket.types";
import { supportActions } from "./ticket.slice";
import { apiRequest } from "lib/api/apiClient";

export function* fetchData() {
  yield* put(supportActions.setIsLoading(true));
  try {
    const link = "/api/v1/ticket";
    const data = yield* call(apiRequest, link);
    if (!data) return;
    yield* put(supportActions.setAllTickets(data));
  } catch (error) {
    console.log(error as Error);
  } finally {
    yield* put(supportActions.setIsLoading(false));
  }
}
export function* fetchIssueData() {
  yield* put(supportActions.setIsLoading(true));
  try {
    const link = "/api/v1/issue";
    const data = yield* call(apiRequest, link);
    if (!data) return;
    yield* put(supportActions.setAllIssues(data));
  } catch (error) {
    console.log(error as Error);
  } finally {
    yield* put(supportActions.setIsLoading(false));
  }
}

export function* onFetchData() {
  yield* takeLatest(SUPPORT_ACTION_TYPES.FETCH_ALL_DATA, fetchData);
  yield* takeLatest(SUPPORT_ACTION_TYPES.FETCH_ALL_DATA, fetchIssueData);
}

export function* supportSagas() {
  yield* all([call(onFetchData)]);
}

"use client";
import { motion } from "framer-motion";
import { Button, ButtonProps } from "@nextui-org/react";
import React, { useState } from "react";
import FilterSvg from "assets/svgs/filters.svg";

type Props = {
  children: React.ReactElement;
};

const RightDrawer = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen((state) => !state);
  };

  return (
    <>
      <div>
        <button
          className="btn btn-sm text-sm bg-white input-bordered text-[#667085] dark:bg-primary-950 dark:text-white h-10"
          onClick={toggleDrawer}>
          <FilterSvg />
          <span className="hidden md:inline lg:inline">Filters</span>
        </button>
        <motion.div
          initial={{ x: "100%" }}
          animate={{ x: isOpen ? "0%" : "100%" }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            height: "100vh",
            width: "300px",
            boxShadow: "-2px 0 5px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
            padding: "20px",
          }}
          className="bg-white dark:bg-primary-950 justify-between flex flex-col">
          <div className="">{children}</div>
          <div className="w-full centered">
            <Button
              color="secondary"
              onPress={toggleDrawer}>
              Close
            </Button>
          </div>
        </motion.div>
      </div>
      {isOpen && (
        <div
          className="bg-white/20 dark:bg-black/20 backdrop-blur-sm fixed z-20 inset-0 cursor-pointer"
          onClick={toggleDrawer}
        />
      )}
    </>
  );
};

export default RightDrawer;

export enum NotificationType {
  notification = "activity",
  withdrawal = "withdrawal",
  deposit = "deposit",
  registration = "registration",
  security = "security",
}
const NotificationIcon = ({
  type = NotificationType.notification,
}: {
  type?: NotificationType | string;
}) => {
  switch (type) {
    case NotificationType.deposit:
      return (
        <span className="bg-green-400/10 text-green-400 flex justify-center items-center w-8 h-8 shadow rounded me-3">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            {/* Generator: Sketch 52.2 (67145) - http://www.bohemiancoding.com/sketch */}
            <title>Stockholm-icons / Communication / Mail-attachment</title>
            <desc>Created with Sketch.</desc>
            <g
              id="Stockholm-icons-/-Communication-/-Mail-attachment"
              stroke="none"
              strokeWidth={1}
              fill="none"
              fillRule="evenodd">
              <rect
                id="bound"
                x={0}
                y={0}
                width={24}
                height={24}
              />
              <path
                d="M14.8571499,13 C14.9499122,12.7223297 15,12.4263059 15,12.1190476 L15,6.88095238 C15,5.28984632 13.6568542,4 12,4 L11.7272727,4 C10.2210416,4 9,5.17258756 9,6.61904762 L10.0909091,6.61904762 C10.0909091,5.75117158 10.823534,5.04761905 11.7272727,5.04761905 L12,5.04761905 C13.0543618,5.04761905 13.9090909,5.86843034 13.9090909,6.88095238 L13.9090909,12.1190476 C13.9090909,12.4383379 13.8240964,12.7385644 13.6746497,13 L10.3253503,13 C10.1759036,12.7385644 10.0909091,12.4383379 10.0909091,12.1190476 L10.0909091,9.5 C10.0909091,9.06606198 10.4572216,8.71428571 10.9090909,8.71428571 C11.3609602,8.71428571 11.7272727,9.06606198 11.7272727,9.5 L11.7272727,11.3333333 L12.8181818,11.3333333 L12.8181818,9.5 C12.8181818,8.48747796 11.9634527,7.66666667 10.9090909,7.66666667 C9.85472911,7.66666667 9,8.48747796 9,9.5 L9,12.1190476 C9,12.4263059 9.0500878,12.7223297 9.14285008,13 L6,13 C5.44771525,13 5,12.5522847 5,12 L5,3 C5,2.44771525 5.44771525,2 6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,12 C19,12.5522847 18.5522847,13 18,13 L14.8571499,13 Z"
                id="Combined-Shape"
                fill="currentColor"
                opacity="0.3"
              />
              <path
                d="M9,10.3333333 L9,12.1190476 C9,13.7101537 10.3431458,15 12,15 C13.6568542,15 15,13.7101537 15,12.1190476 L15,10.3333333 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 L9,10.3333333 Z M10.0909091,11.1212121 L12,12.5 L13.9090909,11.1212121 L13.9090909,12.1190476 C13.9090909,13.1315697 13.0543618,13.952381 12,13.952381 C10.9456382,13.952381 10.0909091,13.1315697 10.0909091,12.1190476 L10.0909091,11.1212121 Z"
                id="Combined-Shape"
                fill="currentColor"
              />
            </g>
          </svg>
        </span>
      );
    case NotificationType.notification:
      return (
        <span className="bg-amber-400/10 text-amber-400 flex justify-center items-center w-8 h-8 shadow rounded me-3">
          <svg
            width={24}
            height={24}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 14 20"
            className="w-5 h-5">
            <path d="M12.133 10.632v-1.8A5.406 5.406 0 0 0 7.979 3.57.946.946 0 0 0 8 3.464V1.1a1 1 0 0 0-2 0v2.364a.946.946 0 0 0 .021.106 5.406 5.406 0 0 0-4.154 5.262v1.8C1.867 13.018 0 13.614 0 14.807 0 15.4 0 16 .538 16h12.924C14 16 14 15.4 14 14.807c0-1.193-1.867-1.789-1.867-4.175ZM3.823 17a3.453 3.453 0 0 0 6.354 0H3.823Z" />
          </svg>
        </span>
      );
    case NotificationType.withdrawal:
      return (
        <span className="bg-orange-400/10 text-orange-400 flex justify-center items-center w-8 h-8 shadow rounded me-3">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            {/* Generator: Sketch 52.2 (67145) - http://www.bohemiancoding.com/sketch */}
            <title>Stockholm-icons / Communication / Chat-check</title>
            <desc>Created with Sketch.</desc>
            <g
              id="Stockholm-icons-/-Communication-/-Chat-check"
              stroke="none"
              strokeWidth={1}
              fill="none"
              fillRule="evenodd">
              <rect
                id="bound"
                x={0}
                y={0}
                width={24}
                height={24}
              />
              <path
                d="M4.875,20.75 C4.63541667,20.75 4.39583333,20.6541667 4.20416667,20.4625 L2.2875,18.5458333 C1.90416667,18.1625 1.90416667,17.5875 2.2875,17.2041667 C2.67083333,16.8208333 3.29375,16.8208333 3.62916667,17.2041667 L4.875,18.45 L8.0375,15.2875 C8.42083333,14.9041667 8.99583333,14.9041667 9.37916667,15.2875 C9.7625,15.6708333 9.7625,16.2458333 9.37916667,16.6291667 L5.54583333,20.4625 C5.35416667,20.6541667 5.11458333,20.75 4.875,20.75 Z"
                id="check"
                fill="currentColor"
                opacity="0.3"
              />
              <path
                d="M2,11.8650466 L2,6 C2,4.34314575 3.34314575,3 5,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,15 C22,15.0032706 21.9999948,15.0065399 21.9999843,15.009808 L22.0249378,15 L22.0249378,19.5857864 C22.0249378,20.1380712 21.5772226,20.5857864 21.0249378,20.5857864 C20.7597213,20.5857864 20.5053674,20.4804296 20.317831,20.2928932 L18.0249378,18 L12.9835977,18 C12.7263047,14.0909841 9.47412135,11 5.5,11 C4.23590829,11 3.04485894,11.3127315 2,11.8650466 Z M6,7 C5.44771525,7 5,7.44771525 5,8 C5,8.55228475 5.44771525,9 6,9 L15,9 C15.5522847,9 16,8.55228475 16,8 C16,7.44771525 15.5522847,7 15,7 L6,7 Z"
                id="Combined-Shape"
                fill="currentColor"
              />
            </g>
          </svg>
        </span>
      );
    case NotificationType.security:
      return (
        <span className="bg-red-400/10 text-red-400 flex justify-center items-center w-8 h-8 shadow rounded me-3">
          <svg
            width={24}
            height={24}
            className="h-4 w-4 transition-colors"
            viewBox="0 0 20 23"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.96587 0.324707C13.6946 2.68842 17.064 3.80704 19.9551 3.54226C20.1827 8.14978 19.5398 11.9061 18.1524 14.8729C17.7964 14.5811 17.4019 14.3399 16.98 14.156C18.1008 11.6715 18.6163 8.5476 18.4277 4.73484C15.98 4.95848 13.1278 4.01186 9.97068 2.01051C7.23935 4.13265 4.42005 4.7541 1.54648 4.60355C1.41912 9.45702 2.27209 13.1109 3.91938 15.7692C5.13734 14.7508 7.21178 14.9048 8.19385 13.5542C8.26431 13.4496 8.29713 13.3936 8.2967 13.3472C8.29582 13.3236 7.2306 12.0172 7.13519 11.8649C6.88398 11.4658 6.41395 10.924 6.41395 10.4562C6.41395 10.1923 6.62227 9.84784 6.92031 9.77125C6.89711 9.37562 6.88136 8.9743 6.88136 8.57692C6.88136 8.34191 6.88617 8.10427 6.89448 7.87144C6.90761 7.72395 6.93475 7.65874 6.9737 7.51651C7.15742 6.91893 7.55716 6.41122 8.09494 6.09242C8.28532 5.97206 8.49232 5.87885 8.70414 5.80226C9.08883 5.66177 8.9024 5.05389 9.32472 5.0447C10.3112 5.01931 11.9344 5.88191 12.5668 6.56682C12.9699 7.01278 13.1996 7.50819 13.2136 8.10864L13.1734 9.8369C13.3484 9.87979 13.5449 10.0172 13.5878 10.1923C13.723 10.738 13.1567 11.4172 12.8937 11.8505C12.6513 12.2509 11.7239 13.3437 11.723 13.3525C11.7186 13.3998 11.7427 13.4584 11.8062 13.5542C12.0451 13.882 12.348 14.121 12.6871 14.3101C12.238 14.5454 11.827 14.8475 11.4683 15.2059C10.5659 16.1083 10.0074 17.3561 10.0074 18.7338C10.0074 19.9106 10.4171 20.9933 11.1011 21.8472C10.7449 22.0108 10.3794 22.164 10.0044 22.3049C3.54957 19.9487 -0.268432 14.1766 0.0147232 3.38734C3.40865 3.56546 6.73912 2.83153 9.96587 0.324707Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.9965 14.7495C17.1966 14.7495 18.9804 16.5333 18.9804 18.7338C18.9804 20.9343 17.1966 22.7181 14.9965 22.7181C12.796 22.7181 11.0122 20.9343 11.0122 18.7338C11.0122 16.5333 12.796 14.7495 14.9965 14.7495ZM14.0206 18.8769C14.1418 18.9798 14.2561 19.0904 14.3628 19.2082C14.6976 18.6695 15.0547 18.1745 15.432 17.7194C16.4981 16.4318 16.0149 16.68 17.4937 16.68L17.288 16.9084C16.6569 17.61 16.4438 17.9749 15.9261 18.7229C15.4134 19.4646 14.9484 20.2382 14.5339 21.0389L14.4061 21.2862L14.288 21.0341C14.0713 20.568 13.8109 20.1404 13.5006 19.7579C13.1908 19.3758 12.9195 19.1264 12.4989 18.8375C12.6919 18.2047 13.6092 18.5286 14.0206 18.8769Z"
              fill="currentColor"
            />
          </svg>
        </span>
      );

    case NotificationType.registration:
      return (
        <span className="bg-blue-600/10 flex justify-center items-center w-8 h-8 shadow rounded me-3">
          <svg
            className="text-blue-600 w-6 h-6"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            {/* Generator: Sketch 52.2 (67145) - http://www.bohemiancoding.com/sketch */}
            <title>Stockholm-icons / Communication / Add-user</title>
            <desc>Created with Sketch.</desc>
            <g
              id="Stockholm-icons-/-Communication-/-Add-user"
              stroke="none"
              strokeWidth={1}
              fill="none"
              fillRule="evenodd">
              <polygon
                id="Shape"
                points="0 0 24 0 24 24 0 24"
              />
              <path
                d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                id="Combined-Shape"
                fill="currentColor"
                opacity="0.3"
              />
              <path
                d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                id="Mask-Copy"
                fill="currentColor"
              />
            </g>
          </svg>
        </span>
      );

    default:
      return <></>;
  }
};
export default NotificationIcon;

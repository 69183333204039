import {
  ActionWithPayload,
  withMatcher,
  createAction,
  Action,
} from "utils/reducer/reducer.utils";
import { COLOR_ACTION_TYPES } from "./color.types";
import { colorActions } from "./color.slice";

type ColorData = {
  name: string;
  code: string;
  callback: () => void;
};
type DeleteColorData = {
  uri: string;
};

type UpdateColorData = {
  name: string;
  code: string;
  uri: string;
};

export type UpdateColor = ActionWithPayload<
  COLOR_ACTION_TYPES.UPDATE_COLOR,
  UpdateColorData
>;
export type AddColor = ActionWithPayload<
  COLOR_ACTION_TYPES.ADD_COLOR,
  ColorData
>;
export type DeleteColor = ActionWithPayload<
  COLOR_ACTION_TYPES.DELETE_COLOR,
  DeleteColorData
>;
export type FetchColors = Action<COLOR_ACTION_TYPES.FETCH_ALL_DATA>;

export const addColor = withMatcher(
  (formValues: ColorData): AddColor =>
    createAction(COLOR_ACTION_TYPES.ADD_COLOR, formValues)
);

export const updateColor = withMatcher(
  (formValues: UpdateColorData): UpdateColor =>
    createAction(COLOR_ACTION_TYPES.UPDATE_COLOR, formValues)
);
export const deleteColor = withMatcher(
  (data: DeleteColorData): DeleteColor =>
    createAction(COLOR_ACTION_TYPES.DELETE_COLOR, data)
);

export const getAllColor = withMatcher(
  (): FetchColors => createAction(COLOR_ACTION_TYPES.FETCH_ALL_DATA)
);

export const setColorSearchValue = (value: string) =>
  colorActions.setSearchValue(value);

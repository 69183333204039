import { takeLatest, all, call, put, select } from "typed-redux-saga";
import { PRODUCT_ACTION_TYPES } from "./product.types";
import {
  DeleteMultipleProducts,
  GetRelatedCategoryData,
  UpdateCurrentProduct,
  UpdateCurrentProductAttr,
  UploadEditProductAttr,
  UploadProductAttr,
} from "./product.actions";
import { selectCurrentAttributes, selectProduct } from "./product.selector";
import { selectColors } from "../color/color.selector";
import { Combination, Product } from "utils/types/products";
import { Color } from "utils/types/color";
import { Attribute, Attributes } from "utils/types/attribute";
import { combineAttributes } from "utils/helper/combination";
import { productActions } from "./product.slice";
import { apiRequest } from "lib/api/apiClient";
import { alertMessage } from "components/toolkit/initial-state.component";
import { logger } from "lib/logger";
import { defaultProductData } from "utils/helper/states";
import { categoryActions } from "store/category/category.slice";

function getCombinations(
  colors: Color[],
  product: Product,
  selectedAttributes: Attributes
): Combination[] {
  const selectedColors = colors.filter((color) =>
    product.colors.includes(color.id)
  );

  const validAttributes = selectedAttributes.filter(
    (attribute) => attribute.values.length > 0
  );

  const attributeCombinations = combineAttributes(validAttributes);

  return selectedColors.length > 0
    ? attributeCombinations.length > 0
      ? selectedColors.flatMap((color) =>
          attributeCombinations.map((attrComb) => ({
            color_id: color.id,
            attribute_combination: attrComb,
            combination_name: `${color.name} - ${attrComb.combination_name}`,
            stock: 10,
            price: product.price,
          }))
        )
      : selectedColors.map((color) => ({
          color_id: color.id,
          attribute_combination: null, // No attribute combinations
          combination_name: color.name,
          stock: 10,
          price: product.price,
        }))
    : attributeCombinations.map((attrComb) => ({
        color_id: null, // No color
        attribute_combination: attrComb,
        combination_name: attrComb.combination_name,
        stock: 10,
        price: product.price,
      }));
}

export function* runUpdateProductData({ payload }: UpdateCurrentProduct) {
  try {
    const { name, value } = payload;
    const product: Product = yield* select(selectProduct);
    const _colors: Color[] | null = yield* select(selectColors);
    const colors: Color[] = _colors || [];

    if (name in product) {
      const productKey = name as keyof Product;
      const currentValueType = typeof product[productKey];

      let newValue: any = value;

      // Perform type conversion if necessary
      if (currentValueType === "number") {
        newValue = Number(value);
      } else if (currentValueType === "boolean") {
        newValue = value === "true" || value === "1";
      } else if (currentValueType === "string") {
        newValue = String(value);
      } else {
        newValue = value;
      }

      const newProduct: Product = {
        ...product,
        [productKey]: newValue,
      };
      if (name === "colors") {
        const _selectedAttributes: Attribute[] | null = yield* select(
            selectCurrentAttributes
          ),
          selectedAttributes = _selectedAttributes || [];

        const combinations = yield* call(
          getCombinations,
          colors,
          newProduct,
          selectedAttributes
        );

        const productWithCombination: Product = {
          ...newProduct,
          variations: combinations,
        };
        console.log("productWithCombination: ", productWithCombination);
        yield* put(productActions.setProduct(productWithCombination));
      } else {
        yield* put(productActions.setProduct(newProduct));
      }
    }
  } catch (error) {
    // Dispatch failure action if something goes wrong
    // yield put(updateProductDataFailure(error.message));
  }
}
export function* runUpdateProductAttribute({
  payload,
}: UpdateCurrentProductAttr) {
  try {
    const { type, value } = payload;
    const product: Product = yield* select(selectProduct);
    const _colors: Color[] | null = yield* select(selectColors);
    const colors: Color[] = _colors || [];
    const selectedAttributes: Attribute[] | null = yield* select(
      selectCurrentAttributes
    );
    if (type === "update") {
      const newAttribute = value;
      if (selectedAttributes == null) {
        const newSelectedAttributes = [newAttribute];
        const combinations = yield* call(
          getCombinations,
          colors,
          product,
          newSelectedAttributes
        );
        const productWithCombination: Product = {
          ...product,
          variations: combinations,
        };
        yield* put(
          productActions.updateSelectedAttributes(newSelectedAttributes)
        );
        yield* put(productActions.setProduct(productWithCombination));
        return;
      }
      const _attr = selectedAttributes.find((i) => i.id === newAttribute.id);
      if (_attr == null) {
        const newSelectedAttributes = [...selectedAttributes, newAttribute];
        const combinations = yield* call(
          getCombinations,
          colors,
          product,
          newSelectedAttributes
        );
        const productWithCombination: Product = {
          ...product,
          variations: combinations,
        };
        yield* put(
          productActions.updateSelectedAttributes(newSelectedAttributes)
        );
        yield* put(productActions.setProduct(productWithCombination));
        return;
      }
      const others = selectedAttributes.filter((i) => i.id !== newAttribute.id);
      const newSelectedAttributes = [...others, newAttribute];
      const combinations = yield* call(
        getCombinations,
        colors,
        product,
        newSelectedAttributes
      );
      const productWithCombination: Product = {
        ...product,
        variations: combinations,
      };
      yield* put(
        productActions.updateSelectedAttributes(newSelectedAttributes)
      );
      yield* put(productActions.setProduct(productWithCombination));
    } else {
      const newAttributes =
          selectedAttributes == null
            ? null
            : selectedAttributes.filter((i) => i.id !== value.id),
        newSelectedAttributes = newAttributes || [];
      const combinations = yield* call(
        getCombinations,
        colors,
        product,
        newSelectedAttributes
      );
      const productWithCombination: Product = {
        ...product,
        variations: combinations,
      };
      yield* put(
        productActions.updateSelectedAttributes(newSelectedAttributes)
      );
      yield* put(productActions.setProduct(productWithCombination));
    }
  } catch (error) {
    // Dispatch failure action if something goes wrong
    // yield put(updateProductDataFailure(error.message));
  }
}

export function* runUploadProduct({ payload }: UploadProductAttr) {
  const { callback, product } = payload;
  yield* put(productActions.setIsLoading(true));
  try {
    const link = "/api/v1/admin/product";
    const data = yield* call(apiRequest, link, {
      method: "post",
      body: product,
    });
    if (!data) return;
    yield* put(productActions.setAllproduct(data));
    yield* put(productActions.setProduct(defaultProductData));
    alertMessage("success", "Product added successfully");
    callback();
  } catch (error) {
    logger.error(error as Error);
    alertMessage("error", "Failed to add Product");
  } finally {
    yield* put(productActions.setIsLoading(false));
  }
}
export function* fetchProductData() {
  try {
    const data = yield* call(apiRequest, "/api/v1/admin/product");
    if (!data) return;
    logger.log(data);

    yield* put(productActions.setAllproduct(data));
  } catch (error) {
    console.log(error as Error);
  }
}

export function* updateProduct({ payload }: UploadEditProductAttr) {
  try {
    yield* put(productActions.setIsLoading(true));
    const data = yield* call(
      apiRequest,
      payload.action,
      {
        method: "put",
        body: payload.product,
      },
      true
    );
    if (!data) return;
    logger.log(data);

    yield* put(productActions.setAllproduct(data));
  } catch (error) {
    console.log(error as Error);
  } finally {
    yield* put(productActions.setIsLoading(false));
  }
}

export function* deleteProducts({ payload }: DeleteMultipleProducts) {
  try {
    // const data = yield* call(apiRequest, "/api/v1/admin/product");
    const data = yield* call(apiRequest, "/api/admin/product/delete", {
      method: "post",
      body: JSON.stringify({ ids: payload }),
    });
    if (!data) return;
    logger.log(data);

    yield* put(productActions.setAllproduct(data));
  } catch (error) {
    console.log(error as Error);
  }
}
export function* fetchAssociatedData({ payload }: GetRelatedCategoryData) {
  try {
    yield* put(categoryActions.setIsLoading(true));
    const data = yield* call(
      apiRequest,
      `/api/v1/related-to-category/${payload.slug}`
    );
    if (!data) return;
    logger.log(data);

    yield* put(productActions.setRelatedData(data));
    yield* put(categoryActions.setIsLoading(false));
  } catch (error) {
    console.log(error as Error);
  }
}
export function* onUpdateProductData() {
  yield* takeLatest(PRODUCT_ACTION_TYPES.UPDATE_PRODUCT, runUpdateProductData);
}
export function* onUpdateProductAttribute() {
  yield* takeLatest(
    PRODUCT_ACTION_TYPES.UPDATE_PRODUCT_ATTRIBUTES,
    runUpdateProductAttribute
  );
}
export function* onUpLoadProduct() {
  yield* takeLatest(PRODUCT_ACTION_TYPES.UPLOAD_PRODUCT, runUploadProduct);
}

export function* onFetchProduct() {
  yield* takeLatest(PRODUCT_ACTION_TYPES.FETCH_ALL_DATA, fetchProductData);
}

export function* onUpdateProduct() {
  yield* takeLatest(PRODUCT_ACTION_TYPES.UPDATE_EDIT_PRODUCT, updateProduct);
}
export function* onDeleteProducts() {
  yield* takeLatest(PRODUCT_ACTION_TYPES.DELETE_PRODUCTS, deleteProducts);
}
//
export function* onRelatedCategoryData() {
  yield* takeLatest(
    PRODUCT_ACTION_TYPES.RELATED_CATEGORY_DATA,
    fetchAssociatedData
  );
}

export function* productSagas() {
  yield* all([
    call(onUpdateProductData),
    call(onUpdateProductAttribute),
    call(onUpLoadProduct),
    call(onFetchProduct),
    call(onDeleteProducts),
    call(onUpdateProduct),
    call(onRelatedCategoryData),
  ]);
}

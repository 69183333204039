import { PayloadAction } from "@reduxjs/toolkit";
import { Products, Product, RetaledCategoryData } from "utils/types/products";
import { ProductState } from "./product.slice";
import { Attribute } from "utils/types/attribute";

export type ProductUpdatePayload = {
  name: keyof Product;
  value: any; // any is used here because we will be doing runtime checks and conversions
};

export const productReducers = {
  setAllproduct(state: ProductState, action: PayloadAction<Products | null>) {
    state.all = action.payload;
  },
  setSearchValue(state: ProductState, action: PayloadAction<string>) {
    state.searchValue = action.payload;
  },
  setIsLoading(state: ProductState, action: PayloadAction<boolean>) {
    state.loading = action.payload;
  },
  setProduct(state: ProductState, action: PayloadAction<Product>) {
    // console.log("action.payload: ", action.payload);

    state.product = action.payload;
  },
  setStartDate(state: ProductState, action: PayloadAction<string | null>) {
    state.start_date = action.payload;
  },
  setRelatedData(
    state: ProductState,
    action: PayloadAction<RetaledCategoryData | null>
  ) {
    state.relatedData = action.payload;
  },
  setEndDate(state: ProductState, action: PayloadAction<string | null>) {
    state.end_date = action.payload;
  },
  setSortOption(state: ProductState, action: PayloadAction<string>) {
    state.sortOption = action.payload;
  },
  setSelectedKeys(state: ProductState, action: PayloadAction<number[]>) {
    state.selectedKeys = action.payload;
  },
  setLimit(state: ProductState, action: PayloadAction<number>) {
    state.limit = action.payload;
  },
  updateSelectedAttributes(
    state: ProductState,
    action: PayloadAction<Attribute[]>
  ) {
    state.selectedAttributes = action.payload;
  },
  updateProductData(
    state: ProductState,
    action: PayloadAction<ProductUpdatePayload>
  ) {
    const { name, value } = action.payload;

    if (name in state.product) {
      const productKey = name as keyof Product;
      const currentValueType = typeof state.product[productKey];

      let newValue: any = value;

      // Perform type conversion if necessary
      if (currentValueType === "number") {
        newValue = Number(value);
      } else if (currentValueType === "boolean") {
        newValue = value === "true" || value === "1";
      } else if (currentValueType === "string") {
        newValue = String(value);
      } else {
        newValue = value;
      }

      state.product = {
        ...state.product,
        [productKey]: newValue,
      };
    }
  },
  setSelectedAttributes(state: ProductState, action: PayloadAction<Attribute>) {
    const newAttribute = action.payload;
    if (state.selectedAttributes == null) {
      state.selectedAttributes = [newAttribute];
    }
    const _attr = state.selectedAttributes.find(
      (i) => i.id === newAttribute.id
    );
    if (_attr == null) {
      state.selectedAttributes = [...state.selectedAttributes, newAttribute];
    }
    const others = state.selectedAttributes.filter(
      (i) => i.id !== newAttribute.id
    );
    state.selectedAttributes = [...others, newAttribute];
  },
  removeSelectedAttributes(
    state: ProductState,
    action: PayloadAction<Attribute>
  ) {
    const newAttribute =
      state.selectedAttributes == null
        ? null
        : state.selectedAttributes.filter((i) => i.id !== action.payload.id);
    state.selectedAttributes = newAttribute;
  },
};

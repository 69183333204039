import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
} from "@nextui-org/react";
import { CiBellOn } from "react-icons/ci";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectRecentActivities } from "store/activity/activity.selector";
import NotificationItem from "./notification-item";

function HeaderNotification() {
  const notifications = useSelector(selectRecentActivities) || [];
  return (
    <Dropdown
      closeOnSelect={true}
      className="max-w-80">
      <DropdownTrigger>
        <Button
          isIconOnly
          variant="light"
          className="relative">
          <CiBellOn size={25} />
          <span className="absolute top-1.5 right-1.5 flex w-1.5 h-1.5">
            <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-sky-400"></span>
            <span className="relative inline-flex w-1.5 h-1.5 rounded-full bg-sky-500"></span>
          </span>
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        aria-label="Dynamic Actions"
        className="h-[80vh]"
        classNames={{
          list: "h-full",
        }}>
        <DropdownSection
          title={"Notification"}
          items={notifications}
          className="h-[85%]"
          classNames={{
            group: "overflow-y-auto h-full",
          }}>
          {(item) => (
            <DropdownItem
              className="dark:hover:bg-dark-base h-fit w-full block"
              key={item.id}>
              <NotificationItem item={item} />
            </DropdownItem>
          )}
        </DropdownSection>
        <DropdownItem
          className="hover:!bg-transparent p-0 h-[15%] centered pt-4"
          key={"view-all"}>
          <div className="w-full p-2">
            <Button
              as={Link}
              to="/notifications"
              color="secondary"
              className="w-full">
              View All
            </Button>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default HeaderNotification;

import { takeLatest, all, call, put } from "typed-redux-saga";

import { blogActions } from "./blog.slice";
import { apiRequest } from "lib/api/apiClient";
import { BLOG_ACTION_TYPES } from "./blog.types";

function* fetchBlog() {
  yield* put(blogActions.setIsLoading(true));
  try {
    const link = "/api/v1/admin/blog";
    const data = yield* call(apiRequest, link);
    if (!data) return;
    yield* put(blogActions.setAllBlog(data));
  } catch (error) {
    console.log(error as Error);
  } finally {
    yield* put(blogActions.setIsLoading(false));
  }
}
export function* fetchTopics() {
  yield* put(blogActions.setIsLoading(true));
  try {
    const link = "/api/v1/admin/topic";
    const data = yield* call(apiRequest, link);
    if (!data) return;
    yield* put(blogActions.setAllTopics(data));
  } catch (error) {
    console.log(error as Error);
  } finally {
    yield* put(blogActions.setIsLoading(false));
  }
}

export function* onFetchData() {
  yield* takeLatest(BLOG_ACTION_TYPES.FETCH_ALL_DATA, fetchTopics);
  yield* takeLatest(BLOG_ACTION_TYPES.FETCH_ALL_DATA, fetchBlog);
}

export function* blogSagas() {
  yield* all([call(onFetchData)]);
}

import { RiSunLine } from "react-icons/ri";
import { Button } from "@nextui-org/react";
import { PiMoonLight } from "react-icons/pi";
import { THEME_KEY } from "utils/helper/states";
import { useDispatch, useSelector } from "react-redux";
import { selectTheme } from "store/theme/theme.selector";
import { setTheme } from "store/theme/theme.action";

const DarkModeToggle = () => {
  const theme = useSelector(selectTheme),
    dispatch = useDispatch(),
    switchTheme = () => {
      const mode = theme === "dark" ? "light" : "dark";
      dispatch(setTheme(mode));
      localStorage.setItem(THEME_KEY, mode);
    };
  return (
    <Button
      isIconOnly
      variant="light"
      onPress={switchTheme}>
      {theme === "light" ? <PiMoonLight size={20} /> : <RiSunLine size={20} />}
    </Button>
  );
};

export default DarkModeToggle;

import { createSelector } from "reselect";
import { RootState } from "../store";
import { ProductItem } from "utils/types/products";
import { sortProducts } from "utils/helper/helper";

const selectProductReducer = (state: RootState) => state.products;
export const selectAllProducts = createSelector(
  [selectProductReducer],
  (data) => data.all
);

const filterProducts = (
  products: ProductItem[] | null,
  searchValue: string,
  startDate: string | null,
  endDate: string | null
): ProductItem[] | null => {
  if (!products) return null;

  return products.filter((product) => {
    const isMatch =
      product.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      product.category.name.toLowerCase().includes(searchValue.toLowerCase());
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;
    const addedDate = new Date(product.added);

    return (
      isMatch && (!start || addedDate >= start) && (!end || addedDate <= end)
    );
  });
};

export const selectProducts = createSelector([selectProductReducer], (data) => {
  if (!data.all) return null;

  const filteredProducts =
    filterProducts(
      data.all,
      data.searchValue,
      data.start_date,
      data.end_date
    ) || [];

  return sortProducts(filteredProducts, data.sortOption);
});

export const selectProduct = createSelector(
  [selectProductReducer],
  (data) => data.product
);

export const selectCurrentAttributes = createSelector(
  [selectProductReducer],
  (data) => data.selectedAttributes
);

export const selectProductIsLoading = createSelector(
  [selectProductReducer],
  (data) => data.loading
);

export const selectSortOption = createSelector(
  [selectProductReducer],
  (data) => data.sortOption
);
export const selectProductLimit = createSelector(
  [selectProductReducer],
  (data) => data.limit
);
export const selectProductStart = createSelector(
  [selectProductReducer],
  (data) => data.start_date
);

export const selectProductEnd = createSelector(
  [selectProductReducer],
  (data) => data.end_date
);

export const selectSelectedKeys = createSelector(
  [selectProductReducer],
  (data) => data.selectedKeys
);

export const selectRelatedCategoryData = createSelector(
  [selectProductReducer],
  (data) => data.relatedData
);

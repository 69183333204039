import { getRoleByID } from "actions/roles";
import { getUserByID } from "actions/user";
import { LoaderFunctionArgs } from "react-router-dom";

export const roleLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  if (id) {
    const data = await getRoleByID(id);
    if (data == null) {
      throw new Response("Not found.", { status: 404 });
    }
    return data;
  }
  return null;
};

export const staffLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  if (id) {
    const data = await getUserByID(id);
    if (data == null) {
      throw new Response("Not found.", { status: 404 });
    }
    return data;
  }
  return null;
};

import { Chip } from "@nextui-org/react";
import { Ticket } from "utils/types/ticket";

function TicketStatus({ ticket }: { ticket: Ticket }) {
  if (ticket.status) {
    if (ticket.status === "in_progress")
      return (
        <Chip
          color="warning"
          variant="flat">
          Processing
        </Chip>
      );

    if (ticket.status === "closed")
      return (
        <Chip
          color="danger"
          variant="flat">
          Closed
        </Chip>
      );
    return (
      <Chip
        color="success"
        variant="flat">
        Open
      </Chip>
    );
  }

  return <Chip variant="flat">Draft</Chip>;
}

export default TicketStatus;

import { takeLatest, all, call, put } from "typed-redux-saga";

import { apiRequest } from "lib/api/apiClient";
import { POLICY_ACTION_TYPES } from "./policy.types";
import { policyActions } from "./policy.slice";

function* fetchPolicies() {
  yield* put(policyActions.setIsLoading(true));
  try {
    const link = "/api/v1/admin/vendorPolicy";
    const data = yield* call(apiRequest, link);
    if (!data) return;
    yield* put(policyActions.setAllPolicy(data));
  } catch (error) {
    console.log(error as Error);
  } finally {
    yield* put(policyActions.setIsLoading(false));
  }
}

export function* onFetchData() {
  yield* takeLatest(POLICY_ACTION_TYPES.FETCH_ALL_DATA, fetchPolicies);
}

export function* policySagas() {
  yield* all([call(onFetchData)]);
}

function ReportSvg({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width={26}
      height={26}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_218_9423)">
        <path
          d="M9.28027 6.60384V10.7705C9.28027 12.7804 10.9162 14.4163 12.9261 14.4163C14.936 14.4163 16.5719 12.7804 16.5719 10.7705V6.60384C16.5719 4.59395 14.936 2.95801 12.9261 2.95801C10.9162 2.95801 9.28027 4.59395 9.28027 6.60384ZM15.5303 6.60384V10.7705C15.5303 12.2064 14.362 13.3747 12.9261 13.3747C11.4902 13.3747 10.3219 12.2064 10.3219 10.7705V6.60384C10.3219 5.1679 11.4902 3.99967 12.9261 3.99967C14.362 3.99967 15.5303 5.1679 15.5303 6.60384Z"
          fill="currentColor"
        />
        <path
          d="M19.6966 10.7692C19.6966 14.5025 16.6591 17.54 12.9258 17.54C9.19245 17.54 6.15495 14.5025 6.15495 10.7692V9.72754H5.11328V10.7692C5.11328 14.9015 8.33984 18.2854 12.4049 18.5551V21.7072H8.7487V22.7489H17.0924V21.7072H13.4466V18.5551C17.5117 18.2854 20.7383 14.9015 20.7383 10.7692V9.72754H19.6966V10.7692Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_218_9423">
          <rect
            width={25}
            height={25}
            fill="white"
            transform="translate(0.425781 0.353516)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ReportSvg;
